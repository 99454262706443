import React from "react";
import DropdownSelect from "../../dropdown/Dropdown";
import TextInput from "../../UIComponent/TextInput";
import Validation from '../../../utils/validation/ValidationText';
import ImageCropperWithUpload from "../../ImageUpload/UploadImage";
import moment from "moment";
import SingleSelectWithSearch from "../../dropdown/SingleSelectWithSearch/SingleSelectWithSearch";
import Selector from "../../dropdown/commonDropdown/Selector";


function IndividualBasicInformation(props) {
  return (
    <div className="w-full">
      <div className="2xl:py-5 2xl:pt-5 lg:py-5 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full items-center">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="grid grid-cols-12 gap-6 w-full ">
              <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6 w-full ">
                  <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                        <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                          First Name <span className="text-[#c00000]">*</span>
                        </h2>
                        <div className="dropdwn-input flex items-center">
                          <DropdownSelect
                            drpIdentity={"mrMrsId"}
                            optionArray={props.mrMrs}
                            setFilterParameters={props.setFilterParameters}
                            value={props.individualProfile.mrMrsId}
                          />
                          <TextInput
                            placeholder=""
                            type="text"
                            name="firstName"
                            value={props.individualProfile.firstName}
                            identity="BasicInformation"
                            handleChange={props.handleChange}
                            onBlur={(e) => props.validate("firstName", props.individualProfile)}

                          // value={this.state.searchbyParent}
                          // handleChange={(event) => {
                          //   this.setState({ searchByName: event.target.value });
                          // }}
                          />
                        </div>
                        <Validation error={props.validState.error.mrMrsId} />

                        <Validation error={props.validState.error.firstName} />
                      </div>
                    </div>

                  </div>

                  <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                        <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                          Last Name <span className="text-[#c00000]">*</span>
                        </h2>
                        <TextInput
                          placeholder=""
                          type="text"
                          name="lastName"
                          value={props.individualProfile.lastName}
                          identity="BasicInformation"
                          handleChange={props.handleChange}
                          onBlur={(e) => props.validate("lastName", props.individualProfile)}

                        // value={this.state.searchbyParent}
                        // handleChange={(event) => {
                        //   this.setState({ searchByName: event.target.value });
                        // }}
                        />
                        <Validation error={props.validState.error.lastName} />
                      </div>
                    </div>
                  </div>

                  <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                        <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                          Job Function <span className="text-[#c00000]">*</span>
                        </h2>
                        {/* <TextInput
                          placeholder=""
                          type="text"
                          name="title"
                          value={props.individualProfile.title}
                          identity="BasicInformation"
                          handleChange={props.handleChange}
                          onBlur={(e) => props.validate("title", props.individualProfile)}
                        /> */}
                        <Selector
                          drpIdentity="Designation"
                          options={props.designationList}
                          isMulti
                          //   options={props.countriesWithPresence}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          // components={{ Option }}
                          onChange={props.handleChangeMultiDropdown}
                          allowSelectAll={true}
                          value={
                            props.individualProfile.selectedDesignation
                          }
                        />
                        <Validation error={props.validState.error.selectedDesignation} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                <div className="company-logo-cropper ml-auto mr-auto">

                  <div className="individual-profile-create-cropper">
                    <ImageCropperWithUpload
                      key="Main"
                      height={180}
                      width={180}
                      isCheckValidation={true}
                      MaxFileSize={2097152}
                      MaxFileSizeMessage={"2MB"}
                      onSaveImage={props.saveMainImage}
                      imageIdentity="IndividualMembers"
                      intitImage={props.individualProfile.photo}
                      aspactRatio={180 / 180}
                      uploadBtn={"Upload Photo"}
                    //dimensionText={"(Dimension: 550px by 260px Maximum Size: 2MB)"}
                    />
                    <div className=" text-center">
                      <Validation error={props.validState.error.photo} />
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Designation <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="employementTitle"
                  value={props.individualProfile.employementTitle}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  onBlur={(e) => props.validate("employementTitle", props.individualProfile)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                <Validation error={props.validState.error.employementTitle} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Contact Number <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="contactNo"
                  value={props.individualProfile.contactNo}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  onBlur={(e) => props.validate("contactNo", props.individualProfile)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                <Validation error={props.validState.error.contactNo} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Organisation <span className="text-[#c00000]">*</span>
                </h2>
                {/* <DropdownSelect
                  drpIdentity={"Organisation"}
                  optionArray={props.organisation}
                  setFilterParameters={props.setFilterParameters}
                  value={props.individualProfile.corporateMemberId}
                /> */}
                {/* <SingleSelectWithSearch
                  drpIdentity={"Organisation"}
                  optionArray={props.filteredOrganisation}
                  value={props.individualProfile.corporateMemberId}
                  setFilterParameters={(id, name, drpIdentity) => props.setFilterParametersForSelector(id, name, drpIdentity)}
                  handleChangeTest={(e, identity) => props.handleChangeForSelector(e, identity)}
                  searchText={props.individualProfile.companyName}
                /> */}
                <SingleSelectWithSearch
                  drpIdentity={"Organisation"}
                  optionArray={props.filteredOrganisation}
                  value={props.individualProfile.corporateMemberId}
                  setFilterParameters={(id, drpIdentity, name) => props.setFilterParametersForSelector(id, name, drpIdentity)}
                  handleChange={(e, identity) => props.handleChangeForSelector(e, identity)}
                  searchText={props.individualProfile.companyName}
                />
                <Validation error={props.validState.error.corporateMemberId} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  {/* Email (please enter company email) */}
                  Business Email
                  <span className="text-[#c00000]">*</span>
                </h2>
                {
                  (props.individualProfile.isNonMember === true) ?
                    <div className="dropdwn-input-email flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="emailText"
                        value={props.individualProfile.emailText}
                        identity="BasicInformation"
                        handleChange={props.handleChange}
                        onBlur={(e) => props.validate("emailText", props.individualProfile)}
                      />
                    </div>
                    :
                    <div className="dropdwn-input-email flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="emailText"
                        value={props.individualProfile.emailText}
                        identity="BasicInformation"
                        handleChange={props.handleChange}
                        onBlur={(e) => props.validate("emailText", props.individualProfile)}
                      />
                      {/* <DropdownSelect
                        disabled={true}
                        drpIdentity={"EmailDomain"}
                        optionArray={props.domainArr}
                        setFilterParameters={props.setFilterParameters}
                        value={props.individualProfile.emailDomainId}
                      /> */}
                    </div>
                }
                <Validation error={props.validState.error.emailText} />
                <Validation error={props.validState.error.emailString} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Company Address Line 1 
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="companyAddress"
                  value={props.individualProfile.companyAddress}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  //onBlur={(e) => props.validate("companyAddress", props.individualProfile)}
                  disabled={true}
                  isCurserAllowed={true}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                {/* <Validation error={props.validState.error.companyAddress} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Company Address Line 2
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="companyAddress2"
                  value={props.individualProfile.companyAddress2}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  // onBlur={(e) => props.validate("companyAddress2", props.individualProfile)}
                  disabled={true}
                  isCurserAllowed={true}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                {/* <Validation error={props.validState.error.companyAddress2} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Company Address Line 3
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="companyAddress3"
                  value={props.individualProfile.companyAddress3}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  // onBlur={(e) => props.validate("companyAddress3", props.individualProfile)}
                  disabled={true}
                  isCurserAllowed={true}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                {/* <Validation error={props.validState.error.companyAddress3} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  {/* Company Number  */}
                  Business Contact Number
                  <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="businessContactNumber"
                  value={props.individualProfile.businessContactNumber}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  onBlur={(e) => props.validate("businessContactNumber", props.individualProfile)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                <Validation error={props.validState.error.businessContactNumber} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Status<span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  drpIdentity={"IndividualStatus"}
                  optionArray={props.individualStatus}
                  setFilterParameters={props.setFilterParameters}
                  value={props.individualProfile.individualStatusId}
                />
                <Validation error={props.validState.error.individualStatusId} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  LinkedIn
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="linkedIn"
                  value={props.individualProfile.linkedIn}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                // onBlur={(e) => props.validate("linkedIn", props.individualProfile)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                {/* <Validation error={props.validState.error.linkedIn} /> */}
              </div>
            </div>
          </div>

          {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Company Member History (Email changes)
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="memberHistory"
                  value={props.individualBasicInformation.linkedIn}
                  identity="MemberHistory"
                  handleChange={props.handleChange}
                // onBlur={(e) => props.validate("linkedIn", props.individualBasicInformation)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                <Validation error={props.validState.error.linkedIn} />
              </div>
            </div>
          </div> */}

          {/* Mailing address 1 and postal code new added */}
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Mailing Address Line 1<span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="mailingAddress1"
                  value={props.individualProfile.mailingAddress1}
                  identity="mailingAddress1"
                  handleChange={props.handleChange}
                  onBlur={(e) => props.validate("mailingAddress1", props.individualProfile)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                <Validation error={props.validState.error.mailingAddress1} />
                {/* <Validation error={props.validState.error.mailingAddress} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Mailing Address Line 2
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="mailingAddress2"
                  value={props.individualProfile.mailingAddress2}
                  identity="mailingAddress2"
                  handleChange={props.handleChange}
                // onBlur={(e) => props.validate("mailingAddress2", props.individualProfile)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                {/* <Validation error={props.validState.error.mailingAddress2} /> */}
                {/* <Validation error={props.validState.error.mailingAddress} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Mailing Address Line 3
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="mailingAddress3"
                  value={props.individualProfile.mailingAddress3}
                  identity="mailingAddress3"
                  handleChange={props.handleChange}
                // onBlur={(e) => props.validate("mailingAddress3", props.individualProfile)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                {/* <Validation error={props.validState.error.mailingAddress3} /> */}
                {/* <Validation error={props.validState.error.mailingAddress} /> */}
              </div>
            </div>
          </div>
          {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Mailing Address 2
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="mailingAddress2"
                  // value={props.individualBasicInformation.mailingAddress}
                  identity="mainingAddress2"
                // handleChange={props.handleChange}
                // onBlur={(e) => props.validate("linkedIn", props.individualBasicInformation)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
              </div>
            </div>
          </div> */}
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Postal code <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="postalCode"
                  maxLength={20}
                  value={props.individualProfile.postalCode}
                  identity="postalCode"
                  handleChange={props.handleChange}
                  onBlur={(e) => props.validate("postalCode", props.individualProfile)}
                />
                <Validation error={props.validState.error.postalCode} />

                {/* <NumberInput
                  placeholder=""
                  name="postalCode"
                  value={props.individualProfile.postalCode}
                  identity="postalCode"
                  handleChange={props.handleChange}
                // onBlur={(e) => props.validate("linkedIn", props.postalCode)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                /> */}
                {/* <Validation error={props.validState.error.postalCode} /> */}
              </div>
            </div>
          </div>
          {/* End mailing address 1 and postal code*/}

          <div className="xl:col-span-12 lg:col-span-12 col-span-12 pr-16">
            <div className="flex items-center justify-between table-bg p-5 lg:py-3">
              <div className="font-bold text-xl text-white tble-th-txt text-left w-[5%]">
                No.
              </div>
              <div className="font-bold text-xl text-white tble-th-txt text-left w-[55%]">
                Email
              </div>
              <div className="font-bold text-xl text-white tble-th-txt w-[20%] text-left">
                Start Date
              </div>
              <div className="font-bold text-xl text-white tble-th-txt text-right w-[20%]">
                End Date
              </div>
            </div>
            {
              props.emailHistory.map((emailList, key) => (
                <div className="flex items-center justify-between p-5 lg:py-3" key={key}>
                  <div className="font-bold text-lg text-[#757575] tble-th-txt text-left w-[5%]">
                    {key + 1}
                  </div>
                  <div className="font-bold text-lg text-[#757575] tble-th-txt text-left w-[55%]">
                    {emailList.previousEmail}
                  </div>
                  <div className="font-bold text-lg text-[#757575] tble-th-txt text-left w-[20%]">
                    {moment(emailList.createdAt).format("DD/MM/YYYY")}
                    {/* {emailList.createdAt} */}
                  </div>
                  <div className="font-bold text-lg text-[#757575] tble-th-txt text-right w-[20%]">
                    {emailList.deletedAt ?
                      moment(emailList.deletedAt).format("DD/MM/YYYY")

                      : null}
                    {/* {emailList.deletedAt} */}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      {/* Save Button */}
      <div className="grid grid-cols-12 gap-6 pb-10">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
          <a
            className="flex items-center justify-end"
            href="#"
          >
            <button className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
              onClick={() => props.onSave()}
            >
              Save
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
export default IndividualBasicInformation;