import moment from "moment";
import React, { Component } from "react";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import PageLoader from "../../components/common/pageLoader/PageLoader";
import Selector from "../../components/dropdown/commonDropdown/Selector";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";

export default class VoucherCodeReport extends Component {
    constructor(props) {
        super(props);
        this.financeServices = new FinanceServices();
        this.swalServices = new SwalServices();
        this.state = {
            options: [
                {
                    value: 1,
                    label: "Event",
                },
                // {
                //     value: 2,
                //     label: "Membership",
                // }
            ],
            voucherReportRequest: {
                currentDate: "",
                voucherCodeUsedFor: ""
            },
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "CreatedAt",
                orderFlag: 0,
                searchText: "",
            },
            voucherReportList: [],
            isLoading: false,
            totalCount: 0,
            validState: {
                isValid: true,
                error: {},
            },
            validationRule: {
                currentDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "date for voucher code report"),
                    },
                ],
                voucherCodeUsedFor: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "type of voucher code usage"),
                    },
                ],
            },
        };
    }

    componentDidMount = () => {
        this.getAllVoucheripReportList();
    }

    reset = () => {
        let resetDetails = this.state.voucherReportRequest
        resetDetails.currentDate = ""
        resetDetails.voucherCodeUsedFor = ""
        this.setState({ voucherReportRequest: resetDetails })
    }

    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.voucherReportRequest,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.voucherReportRequest,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    getAllVoucheripReportList = () => {

        this.setState({ isLoading: true });
        let pagination = { ...this.state.pagination };
        this.financeServices.getAllVoucherCodeReportList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let details = response.responseItem.responseContent.itemList;
                this.setState({
                    voucherReportList: details,
                    totalCount: response.responseItem.responseContent.totalCount,
                })
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }

    handleChangeInDropdown(selected, drpIdentity) {
        if (drpIdentity === "voucherCode") {
            let dataArr = this.state.voucherReportRequest
            if (selected.value > 0) {
                dataArr.voucherCodeUsedFor = selected.label;
            } else {
                dataArr.voucherCodeUsedFor = "";
            }
            this.setState({ voucherReportRequest: dataArr }, () => {
                this.validateField("voucherCodeUsedFor");
            })
        }
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'currentDate') {
            let date = this.state.voucherReportRequest
            date.currentDate = value
            this.setState({ currentDate: date })
        }
    }

    generateVoucherReport = () => {
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields) {
            if (this.state.voucherReportRequest.currentDate) {
                this.setState({ isLoading: true });
                let request = {};
                request.currentDate = moment(this.state.voucherReportRequest.currentDate).format("DD MMM YYYY");
                request.voucherCodeUsedFor = this.state.voucherReportRequest.voucherCodeUsedFor;
                this.financeServices.generateVoucherCodeReport(request).then((response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        this.swalServices.Success("Voucher record generated successfully.");
                        this.getAllVoucheripReportList();
                        this.reset()
                    }
                    else if (response.statusCode === 409) {
                        this.swalServices.Error(response.message);
                        this.reset()
                    }
                    else {
                        this.swalServices.Error(response.message);
                        this.reset()
                    }
                    this.setState({ isLoading: false });
                });
            }
        }
    }
    downloadSheetByVoucherCodeReportId = (voucherCodeReportId, voucherCodeUsedFor, voucherCodeUsedStartDate) => {
        this.setState({ isLoading: true });
        let request = [];
        request.push(voucherCodeReportId);
        this.financeServices.downloadSheetByVoucherCodeReportId(request).then((response) => {
            if (response.status === 200 && response.data != null) {
                const formatter = new Intl.DateTimeFormat('en-GB', { month: 'long' });
                const month = formatter.format(new Date(voucherCodeUsedStartDate));
                const year = new Date(voucherCodeUsedStartDate).getFullYear();
                let blob = new Blob([response.data])
                saveAs(blob, `${voucherCodeUsedFor}_VoucherReport_${month}_${year}` + ".xlsx");
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }

    render() {
        return (
            <div className="main-body-section finance-body-sec">
                <div className="bg-white">
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Date<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="">
                                <input
                                    id="currentDate"
                                    name="currentDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.voucherReportRequest.currentDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    onBlur={() => this.validateField("currentDate")}
                                //    disabled
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.currentDate} />
                        </div>

                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Voucher Code Type<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="">
                                <Selector
                                    options={this.state.options}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    onChange={this.handleChangeInDropdown.bind(this)}
                                    allowSelectAll={false}
                                    value={this.state.options.filter(item => item.label === this.state.voucherReportRequest.voucherCodeUsedFor)}
                                    drpIdentity="voucherCode"
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.voucherCodeUsedFor} />
                        </div>

                        <div className="col-span-4">
                            <button className="btn btn-red text-xl border text-white mt-8" onClick={() => this.generateVoucherReport()}>
                                Generate Voucher Report
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 w-full items-center justify-center px-8 py-10">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                            {this.state.isLoading ? <PageLoader /> :
                                <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table border">
                                    <thead>
                                        <tr className="text-base font-bold flex">
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                                                <div className="font-bold tble-th-txt text-left">
                                                    Generate Date
                                                </div>
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                                                <div className="font-bold tble-th-txt text-left">
                                                    Used Count
                                                </div>
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                                                <div className="font-bold tble-th-txt text-left">
                                                    Used For
                                                </div>
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                                                <div className="font-bold tble-th-txt text-left">
                                                    Start Date
                                                </div>
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                                                <div className="font-bold tble-th-txt text-left">
                                                    End Date
                                                </div>
                                            </th>

                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                                                <div className="font-bold tble-th-txt text-center">Action</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="scrollit text-sm custom-tbody">
                                        {this.state.voucherReportList.map((e, key) => {
                                            return (
                                                <tr className="custom-tbl-td flex items-center" key={key}>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%] ">
                                                        {moment(e.voucherCodeReportGenerationDate).format("DD MMM YYYY")}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                                                        {e.voucherCodeUsedCount}
                                                    </td>

                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                                                        {e.voucherCodeUsedFor}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                                                        {moment(e.voucherCodeUsedStartDate).format("DD MMM YYYY")}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                                                        {moment(e.voucherCodeUsedEndDate).format("DD MMM YYYY")}
                                                    </td>

                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                                        <button
                                                            className="action-btn mx-auto flex items-center"
                                                            aria-controls="add-edit-modal"
                                                            onClick={() => {
                                                                this.downloadSheetByVoucherCodeReportId(e.voucherCodeReportId, e.voucherCodeUsedFor, e.voucherCodeUsedStartDate);
                                                            }}
                                                        >
                                                            <p className="ml-2 tool-tip-txt underline text-[#81a3b8]">
                                                                Download
                                                            </p>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
