import React from "react";
import DatePicker from "../../../datePicker/DatePicker";
import DropdownSelect from "../../../dropdown/Dropdown";
import TextInput from "../../../UIComponent/TextInput";
import moment from "moment";
import Validation from "../../../../utils/validation/ValidationText";
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader";


function SIISCorporateAccountInformation(props) {
  return (
    <div>
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Account Status
                  <span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  disabled={true}
                  drpIdentity={"AccountStatus"}
                  optionArray={props.status}
                  setFilterParameters={props.setFilterParameters}
                  value={props.siisAccountInformation.accountStatus ? props.siisAccountInformation.accountStatus : 7}
                // onBlur={(e) => props.validate("isAccountActive", props.corporateSIISAccount)}
                />
                {/* <Validation error={props.validState.error.isAccountActive} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Reasons for Leaving
                </h2>

                <DropdownSelect
                  drpIdentity={"corporateReasonLeaving"}
                  optionArray={props.reason}
                  setFilterParameters={props.setFilterParameters}
                  value={props.siisAccountInformation.corporateReasonLeavingId ? props.siisAccountInformation.corporateReasonLeavingId : 0}
                // onBlur={(e) => props.validate("corporateReasonLeavingId", props.corporateSIISAccount)}
                />
                {/* <Validation error={props.validState.error.corporateReasonLeavingId} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Joined Date
                </h2>
                <div className="">
                  <DatePicker
                    name="corporatejoinedDate"
                    handleChange={props.handleChange}
                    identity="SIISInformation"
                    value={props.siisAccountInformation.corporatejoinedDate ? moment(props.siisAccountInformation.corporatejoinedDate).format("yyyy-MM-DD") : ''}
                    isCurserAllowed={true}
                    isDisable={true}
                  // onBlur={(e) => props.validate("corporateJoinedDate", props.corporateSIISAccount)}
                  />
                </div>
                {/* <Validation error={props.validState.error.corporateJoinedDate} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Updated Date
                </h2>
                <div className="disabled-input">
                  {props.siisAccountInformation.updatedAt === "" || props.siisAccountInformation.updatedAt === null ?

                    <TextInput
                      disabled={true}
                      placeholder=""
                      type="text"
                      name="linkedIn"
                      value="Unavailable"
                      identity="BasicInformation"
                    />
                    :
                    <DatePicker
                      isDisable={true}
                      name="updatedAt"
                      handleChange={props.handleChange}
                      identity="updatedAt"
                      value={props.siisAccountInformation.updatedAt ? moment(props.siisAccountInformation.updatedAt).format("yyyy-MM-DD") : ''}
                    />

                  }
                </div>
                {/* <div className="disabled-input">
                  <DatePicker
                    name="updatedAt"
                    isDisable={true}
                    handleChange={props.handleChange}
                    identity="SIISInformation"
                    value={moment(props.siisAccountInformation.updatedAt).format("yyyy-MM-DD")}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Updated by
                </h2>
                <DropdownSelect
                  disabled={false}
                  drpIdentity={"updatedById"}
                  optionArray={props._by}
                  setFilterParameters={props.setFilterParameters}
                  value={props.siisAccountInformation.createdBy ? props.siisAccountInformation.createdBy : 1}
                // onBlur={(e) => props.validate("updatedById", props.corporateSIISAccount)}
                />
                {/* <Validation error={props.validState.error.updatedById} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  SIIS Corporate ID
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input dropdwn-input-email flex items-center">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="Updated"
                    value={props.siisAccountInformation.siisAccountId ? props.siisAccountInformation.siisAccountId : ""}
                    disabled={true}
                  // identity="BasicInformation"
                  // handleChange={props.handleChange}
                  // onBlur={(e) =>
                  //   props.validate(
                  //     "areaofExpertise",
                  //     props.corporateBasicInformation
                  //   )
                  // }

                  // value={this.state.searchbyParent}
                  // handleChange={(event) => {
                  //   this.setState({ searchByName: event.target.value });
                  // }}
                  />

                  {/* <Validation error={props.validState.error.areaofExpertise} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Engagement Score
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input dropdwn-input-email flex items-center">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="LastUpdated"
                    disabled={true}
                  // value={
                  //   props.corporateBasicInformation.areaofExpertise
                  // }
                  // identity="BasicInformation"
                  // handleChange={props.handleChange}
                  // onBlur={(e) =>
                  //   props.validate(
                  //     "areaofExpertise",
                  //     props.corporateBasicInformation
                  //   )
                  // }

                  // value={this.state.searchbyParent}
                  // handleChange={(event) => {
                  //   this.setState({ searchByName: event.target.value });
                  // }}
                  />

                  {/* <Validation error={props.validState.error.areaofExpertise} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Renewal Notes
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="dropdwn-input-email flex items-center">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="renewalNotes"
                    value={props.siisAccountInformation.renewalNotes ? props.siisAccountInformation.renewalNotes : ""}
                    identity="SIISInformation"
                    handleChange={props.handleChange}
                  // onBlur={(e) =>
                  //   props.validate(
                  //     "areaofExpertise",
                  //     props.corporateBasicInformation
                  //   )
                  // }

                  // value={this.state.searchbyParent}
                  // handleChange={(event) => {
                  //   this.setState({ searchByName: event.target.value });
                  // }}
                  />

                  {/* <Validation error={props.validState.error.areaofExpertise} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Internal Flag
                </h2>

                <DropdownSelect
                  drpIdentity={"corporateInternalFlags"}
                  optionArray={props.corporateInternalFlags}
                  setFilterParameters={props.setFilterParameters}
                  value={props.siisAccountInformation.internalFlagId ? props.siisAccountInformation.internalFlagId : 0}
                />
                {/* <Validation error={props.validState.error.corporateProspectSourceId}/> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Domain Information
                  <span className="text-[#c00000]">*</span>
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="dropdwn-input-email flex items-center">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="companyDomain"
                    disabled={false}
                    value={props.siisAccountInformation.companyDomain ? props.siisAccountInformation.companyDomain : ""}
                    identity="SIISInformation"
                    handleChange={props.handleChange}
                    isCurserAllowed={false}
                    onBlur={(e) => props.validate("companyDomain", props.siisAccountInformation)}
                  // onBlur={(e) =>
                  //   props.validate(
                  //     "areaofExpertise",
                  //     props.corporateBasicInformation
                  //   )
                  // }

                  // value={this.state.searchbyParent}
                  // handleChange={(event) => {
                  //   this.setState({ searchByName: event.target.value });
                  // }}
                  />

                  {/* <Validation error={props.validState.error.areaofExpertise} /> */}
                </div>
                <div>
                  <Validation error={props.validState.error.companyDomain} />
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-12 md:col-span-12 col-span-12 my-3 mt-5 ">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-12 md:col-span-12 col-span-12">
                <div className="flex items-center justify-between">
                  <div className="check-box-sec flex items-center">
                    {/* <input
                        className="form-checkbox"
                        type="checkbox"
                        name="isFeatured"
                        id="featured" 
                        onChange={(event) => props.handleChange(event,'SIISInformation')}
                        checked={props.siisAccountInformation.featured}
                        value={props.siisAccountInformation.featured}
                      /> */}
                    <input
                      id="isFeatured"
                      name="isFeatured"
                      type="checkbox"
                      className="form-checkbox"
                      checked={props.siisAccountInformation.isFeatured ? props.siisAccountInformation.isFeatured : false}
                      value={props.siisAccountInformation.isFeatured ? props.siisAccountInformation.isFeatured : false}
                      onChange={(event) => props.handleChangeCheckBoxSIIS(event)}
                    //onChange={(e) => props.handleChange(e)}
                    />
                    <span className="filter-lable font-bold text-[#757575] pl-4">
                      Featured
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-12 md:col-span-12 col-span-12 my-3">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-12 md:col-span-12 col-span-12">
                <div className="flex items-center justify-between">
                  <div className="check-box-sec flex items-center">
                    {/* <input
                        className="form-checkbox"
                        type="checkbox"
                        name="isFeatured"
                        id="featured" 
                        onChange={(event) => props.handleChange(event,'SIISInformation')}
                        checked={props.siisAccountInformation.featured}
                        value={props.siisAccountInformation.featured}
                      /> */}
                    <input
                      id="isGoGlobal"
                      name="isGoGlobal"
                      type="checkbox"
                      className="form-checkbox"
                      checked={props.siisAccountInformation.isGoGlobal ? props.siisAccountInformation.isGoGlobal : false}
                      value={props.siisAccountInformation.isGoGlobal ? props.siisAccountInformation.isGoGlobal : false}
                      onChange={(event) => props.handleChangeCheckBoxSIIS(event)}
                    //onChange={(e) => props.handleChange(e)}
                    />
                    <span className="filter-lable font-bold text-[#757575] pl-4">
                      Go Global
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      {/* Save Button */}
      < div className="grid grid-cols-12 gap-6 pb-10" >
        <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
          <a
            className="flex items-center justify-end"
          //href="/CreateCorporateMembers"
          >
            {
              props && props.isLoading === true ?
                (
                  <ButtonLoader />
                )
                :
                (
                  <button
                    className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                    onClick={() => props.onSave()}
                  >
                    Save
                  </button>
                )
            }
          </a>
        </div>
      </div >
    </div >
  );
}

export default SIISCorporateAccountInformation;
