import React, { Component } from "react";
import { Link } from "react-router-dom";
import AccountInformation from "../../../components/membership/createIndividualMember/AccountInformation";
import IndividualBasicInformation from "../../../components/membership/createIndividualMember/IndividualBasicInformation";
import EngagementTrackingNotes from "../../../components/membership/createIndividualMember/EngagementTrackingNotes";
import CommitteeInformation from "../../../components/membership/createIndividualMember/CommitteeInformation";
import CorporateMembershipSubscription from "../../../components/membership/createIndividualMember/CorporateMembershipSubscription";
import CorporateChapterInformation from "../../../components/membership/createIndividualMember/CorporateChapterInformation";
import EmploymentInformation from "../../../components/membership/createIndividualMember/EmploymentInformation";
import IndividualContribution from "../../../components/membership/createIndividualMember/IndividualContribution";
import SGTechInterest from "../../../components/membership/createIndividualMember/SGTechInterest";
import CommunicationCard from "../../../components/membership/createIndividualMember/CommunicationCard";
import FinancialsRecord from "../../../components/membership/corporateMembers/FinancialsRecord";
import EventsBookings from "../../../components/membership/createIndividualMember/EventsBookings";
import IndividualMemberService from "../../../services/axiosServices/apiServices/IndividualMemberService";
import SwalServices from "../../../services/swalServices/SwalServices"
import { CommonValidationMessages, CommonSuccessMessages } from "../../../utils/Messages";
import { validate, isValidForm } from "../../../utils/validation/CommonValidator";
import { CommitteeCardValRules, EmploymentInformationValRules, EngagementTrackingValRules, IndividualContributionValRules, IndividualProfileValRules, SIISIndividualAccountValRules } from "./IndividualValidationRules";

export default class CreateIndividualMembersNew extends Component {
  constructor(props) {
    super(props);
    this.individualMemberServices = new IndividualMemberService();
    this.swalServices = new SwalServices();
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        searchParentName: "",
        searchGroupName: "",
        searchGroupType: "",
        status: 0,
        filterByType: 0,
        filterByStatus: 0,
      },
      editDetails: 0,
      addNew: false,
      openSection: "SIISIndividualAccountInformation",
      mrMrs: [],
      roles: [],
      organisation: [],
      status: [],
      _By: [],
      jobFunction: [],
      jobLevel: [],
      reason: [],
      sponsorship: [],
      matching: [],
      programs: [],
      engagementType: [],
      committeeList: [],
      recordType: [],
      billingCategory: [],
      membershipTypes: [],
      companyCategory: [],
      emailDomain: [],
      domainArr: [],
      individualStatus: [],
      selectedCommitteeList: [],
      parentList: [],
      chapterList: [],
      chaptersList: [],


      emailHistory: [
        {
          id: 1,
          email: "abctech@gmail.com",
          startDate: "02/05/2022",
          endDate: "31/05/2022",
        },
        {
          id: 2,
          email: "bhavesh@gmail.com",
          startDate: "01/06/2022",
          endDate: "30/06/2022",
        },
        {
          id: 3,
          email: "jamie.tan@gmail.com",
          startDate: "01/07/2022",
          endDate: "31/07/2022",
        }
      ],
      //individualMemberId: 0,

      // New individual Account Akshay 

      individualMemberId: 0,
      corporateMemberId: 0,

      deletionReasonList : [],
      siisIndividualAccountInfo: {
        individualSIISAccountId: 0,
        individualMemberId: 0,
        accountStatus: 0,
        individualJoinedDate: "",
        updatedBy: 0,
        updatedAt: "",
        markForDeleted: false,
        deletionReasonId: 0,
        deletedAt: "",
        deletionDate: "",
        engagementScore: 0,
        sIISIndividualId: "",
      },
      engagementTrackingAndNotes: {
        individualMemberId: 0,
        individualEngagementId: 0,
        corporateEngagementTypeId: 0,
        engagementDate: "",
        engagementDescription: "",
        corporateEngagementRecordedById: 0,
        corporateEngagementFollowUpById: 0,
        updatedBy: 0,
        updatedAt: "",
      },
      committeeCard: {
        IndividualCommitteeMappingId: 0,
        individualMemberId: 0,
        workgroupMappingId: 0,
        groupName: "",

      },
      individualProfile: {
        individualMemberId: 0,
        mrMrsId: 0,
        firstName: "",
        lastName: "",
        title: "",
        contactNo: "",
        corporateMemberId: 0,
        email: "",
        companyAddress: "",
        businessContactNumber: "",
        individualStatusId: 0,
        linkedIn: "",
        mailingAddress1: "",
        postalCode: "",
        photo: "",
        emailText: "",
        emailDomainId: 0,
        emailString: "",
      },
      employmentInformation: {
        individualEmploymentId: 0,
        employmentCompanyName: "",
        employmentJobFunctionId: 0,
        employmentJobLevelId: 0,
        individualMemberId: 0,
      },
      individualContribution: {
        individualContributionId: 0,
        name: "",
        roleId: 0,
        startDate: "",
        endDate: "",
        individualMemberId: 0,
        workgroupMappingId: 0,
        chapterInvolvement: true,
        committeeInvolvement: true,
      },
      sgtechInterest: {
        individualMemberId: 0,
        corporateReasonId: "",
        corporateSponsorshipId: "",
        corporateMatchingId: "",
        corporateStrategicProgrammesId: "",
        updatedBy: 0,
        updatedAt: "",
        selectedReason: [],
        selectedSponsorship: [],
        selectedMatching: [],
        selectedProgram: [],

      },

      sgtechCommunication: {
        isAdHocActivityEvent: false,
        isMonthlySGTechEdition: true,
        isFortnightlyEvents: true,
        isFortnightlyGlobal: true,
        isBusinessOpportunities: true,
        individualMemberId: 0,
      },

      membershipSubscription: {
        corporateMemberSubscriptionId: 0,
        membershipTypeId: 1,
        billingCategoryId: 1,
        subscriptionStartDate: null,
        subcriptionEndDate: null,
        updatedBy: 0,
        updatedAt: null,
      },
      chapterInformation: {
        workgroupMappingId: 1,
        corporateMemberId: 0,
        availableChapters: 0,
        joinedChapters: 0,
        updatedBy: 1,
        updatedAt: null
      },


      validStateSIIS: {
        isValid: true,
        error: {},
      },
      validStateEngagement: {
        isValid: true,
        error: {},
      },
      validStateCommittee: {
        isValid: true,
        error: {},
      },
      validStateIndProfile: {
        isValid: true,
        error: {},
      },
      validStateEmpInfo: {
        isValid: true,
        error: {},
      },
      validStateIndContribution: {
        isValid: true,
        error: {},
      },
      validStateIndInterest: {
        isValid: true,
        error: {},
      },








      /// End Portion 




























      individualBasicInformation: {
        individualMemberId: 0,
        individualStatusId: 0,
        mrMrsId: 0,
        firstName: "",
        lastName: "",
        title: "",
        contactNo: "",
        corporateMemberId: 0,
        email: "",
        companyAddress: "",
        companyUEN: "",
        linkedIn: "",
        emailDomainId: 0,
        emailText: "",
        emailString: "",

      },
      sIISCorporateInformation: {
        individualSIISAccountId: 0,
        isAccountActiveStatus: 0,
        joinedDate: "",
        updatedbyId: "",
        sIISAccountUpdatedDate: "",
        employmentCompanyName: "",
        employmentJobFunctionId: 0,
        employmentJobLevelId: 0,
        corporateSGTechId: "",
        corporateSponsorshipId: "",
        corporateMatchingId: "",
        corporateStrategicProgrammesId: "",
        isAdHocActivityEvent: false,
        isMonthlySGTechEdition: false,
        isSGTechAnnouncements: false,
        isFortnightlyEvents: false,
        isBusinessOpportunities: false,
        isTalentCapabilities: false,
        individualMemberId: 0,
        selectedSGTech: [],
        selectedSponsorship: [],
        selectedMatching: [],
        selectedProgram: [],

      },
      engagementTrackingDetails: {
        individualMemberId: 0,
        individualEngagementId: 0,
        corporateEngagementTypeId: 0,
        engagementDate: "",
        engagementDescription: "",
        corporateEngagementRecordedById: 0,
        corporateEngagementFollowUpById: 0,
      },
      committeInformation: {
        individualCommitteeMappingId: 0,
        workgroupMappingId: 0,
        individualMemberId: 0,
        groupName: "",

      },
      corporateMemberSubscription: {
        individualSubscriptionId: 0,
        corporateMemberRecordTypeId: 0,
        companyCategoryId: 0,
        corporateMemberBillingCategoryId: 0,
        subscriptionJoinDate: "",
        subscriptionEndDate: "",
        isSubscriptionAccountStatus: 0,
        updatedById: 0,
        subscriptionLastUpdatedDate: "",
        subscriptionReasonLeaving: "",
        subscriptionRenewalNotes: "",
        individualMemberId: 0,
      },
      contributionDetails: {
        individualContributionId: 0,
        name: "",
        roleId: "",
        startDate: "",
        endDate: "",
        contributionSection: "",
        individualMemberId: 0,
        workgroupMappingId: 0,
      },
      engagemmentTrackingList: [],
      individualContributionList: [],
      validState: {
        isValid: true,
        error: {},
      },

      validationRule: {
        firstName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "first name"),
          }
        ],
        lastName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "last name"),
          }
        ],
        title: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "title"),
          }
        ],
        contactNo: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "contact no"),
          }
        ],
        corporateMemberId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "organisation"),
          }
        ],
        emailText: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "email"),
          }
        ],
        emailString: [
          {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
          }
        ],
        companyAddress: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "company address"),
          }
        ],
        companyUEN: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "company number "),
          }
        ],
        individualStatusId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "status"),
          }
        ],
        linkedIn: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "linkedIn"),
          }
        ],
        emailDomainId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "company domain"),
          }
        ]

      },
      corporateInformationvalidationRule: {
        isAccountActiveStatus: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "account status"),
          }
        ],
        joinedDate: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "joined date"),
          }
        ],
        updatedbyId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "updated by"),
          }
        ],

        // sIISAccountUpdatedDate: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "Updated Date"),
        //   }
        // ],
        employmentCompanyName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "company name"),
          }
        ],
        employmentJobFunctionId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "job function"),
          }
        ],

        employmentJobLevelId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "job level"),
          }
        ],
        // corporateSGTechId: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "SGTech"),
        //   }
        // ],
        // corporateSponsorshipId: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "sponsorship"),
        //   }
        // ],
        // corporateMatchingId: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "matching"),
        //   }
        // ],
        // corporateStrategicProgrammesId: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "strategic programmes"),
        //   }
        // ],
      },

      engagementvalidationRule: {
        corporateEngagementTypeId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "engagement type"),
          }
        ],

        engagementDate: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "engagement date"),
          }
        ],
        engagementDescription: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "description"),
          }
        ],
        corporateEngagementRecordedById: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "recorded by"),
          }
        ],
        corporateEngagementFollowUpById: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "follow up by"),
          }
        ],
      },

      corporateMemberSubscriptionValidationRule: {
        // individualSubscriptionId: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "recorded by"),
        //   }
        // ],
        corporateMemberRecordTypeId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "recorded by"),
          }
        ],
        companyCategoryId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "company category"),
          }
        ],
        corporateMemberBillingCategoryId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "billing category"),
          }
        ],
        subscriptionJoinDate: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "join date"),
          }
        ],
        subscriptionEndDate: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "subscription end date"),
          }
        ],
        isSubscriptionAccountStatus: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", " account status"),
          }
        ],
        updatedById: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "updated by"),
          }
        ],
        subscriptionLastUpdatedDate: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "last updated date"),
          }
        ],
        subscriptionReasonLeaving: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "reasons for leaving"),
          }
        ],
        subscriptionRenewalNotes: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "renewal notes"),
          }
        ],
      },

      individualContributionValidationRule: {
        // name: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "name"),
        //   }
        // ],
        roleId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "role"),
          }
        ],
        startDate: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "startDate"),
          }
        ],
        workgroupMappingId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "chapter/committee name"),
          }
        ],
        // endDate: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "endDate"),
        //   }
        // ],
        // contributionSection: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "title "),
        //   }
        // ],
      },

      committeInformationValidationRule: {
        workgroupMappingId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "join committee"),
          }
        ],
      },
    };
  }

  // Validation for all segments  Akshay

  validateSIISIndividualAccount = (key) => {
    
    const returnValidState = validate(key, this.state.siisIndividualAccountInfo, SIISIndividualAccountValRules, this.state.validStateSIIS);
    this.setState({ validStateSIIS: returnValidState });
  }
  isValidSIIS = () => {
    
    const returnValidState = isValidForm(this.state.siisIndividualAccountInfo, SIISIndividualAccountValRules, this.state.validStateSIIS);
    this.setState({ validStateSIIS: returnValidState });
    return returnValidState.isValid;

  }

  validateIndividualProfile = (key) => {
    const returnValidState = validate(key, this.state.individualProfile, IndividualProfileValRules, this.state.validStateIndProfile);
    this.setState({ validStateIndProfile: returnValidState });
  }
  isValidIndividualProfile = () => {
    
    const returnValidState = isValidForm(this.state.individualProfile, IndividualProfileValRules, this.state.validStateIndProfile);
    this.setState({ validStateIndProfile: returnValidState });
    return returnValidState.isValid;
  }

  validateIndividualEngagement = (key) => {
    const returnValidState = validate(key, this.state.engagementTrackingAndNotes, EngagementTrackingValRules, this.state.validStateEngagement);
    this.setState({ validStateEngagement: returnValidState });
  }
  isValidIndividualEngagement = () => {
    
    const returnValidState = isValidForm(this.state.engagementTrackingAndNotes, EngagementTrackingValRules, this.state.validStateEngagement);
    this.setState({ validStateEngagement: returnValidState });
    return returnValidState.isValid;
  }

  validateIndividualEmployment = (key) => {
    
    const returnValidState = validate(key, this.state.employmentInformation, EmploymentInformationValRules, this.state.validStateEmpInfo);
    this.setState({ validStateEmpInfo: returnValidState });
  }
  isValidIndividualEmployment = () => {
    
    const returnValidState = isValidForm(this.state.employmentInformation, EmploymentInformationValRules, this.state.validStateEmpInfo);
    this.setState({ validStateEmpInfo: returnValidState });
    return returnValidState.isValid;
  }

  validateIndividualContribution = (key) => {
    const returnValidState = validate(key, this.state.individualContribution, IndividualContributionValRules, this.state.validStateIndContribution);
    this.setState({ validStateIndContribution: returnValidState });
  }
  isValidIndividualContribution = () => {
    
    const returnValidState = isValidForm(this.state.individualContribution, IndividualContributionValRules, this.state.validStateIndContribution);
    this.setState({ validStateIndContribution: returnValidState });
    return returnValidState.isValid;
  }

  validateCommitteeCard = (key) => {
    const returnValidState = validate(key, this.state.committeeCard, CommitteeCardValRules, this.state.validStateCommittee);
    this.setState({ validStateCommittee: returnValidState });
  }
  isValidCommitteeCard = () => {
    
    const returnValidState = isValidForm(this.state.committeeCard, CommitteeCardValRules, this.state.validStateCommittee);
    this.setState({ validStateCommittee: returnValidState });
    return returnValidState.isValid;
  }


  ////////////////////////////////////////////
  //validation for every field
  validate = (key, object) => {
    const returnValidState = validate(key, object, this.state.validationRule, this.state.validState);
    this.setState({
      validState: returnValidState
    });
  }

  // validation for whole form
  isValid = () => {
    const returnValidState = isValidForm(this.state.individualBasicInformation, this.state.validationRule, this.state.validState);
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  }

  corporateInformationValidate = (key, object) => {
    const returnValidState = validate(key, this.state.sIISCorporateInformation, this.state.corporateInformationvalidationRule, this.state.validState);
    this.setState({
      validState: returnValidState
    });
  }

  isValidcorporateInformation = () => {
    const returnValidState = isValidForm(this.state.sIISCorporateInformation, this.state.corporateInformationvalidationRule, this.state.validState);
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  }

  engagementValidate = (key, object) => {
    const returnValidState = validate(key, this.state.engagementTrackingDetails, this.state.engagementvalidationRule, this.state.validState);
    this.setState({
      validState: returnValidState
    });
  }

  isValidengagement = () => {
    const returnValidState = isValidForm(this.state.engagementTrackingDetails, this.state.engagementvalidationRule, this.state.validState);
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  }

  corporateMemberSubscriptiontValidate = (key, object) => {
    const returnValidState = validate(key, this.state.corporateMemberSubscription, this.state.corporateMemberSubscriptionValidationRule, this.state.validState);
    this.setState({
      validState: returnValidState
    });
  }

  isValidCorporateMemberSubscription = () => {
    const returnValidState = isValidForm(this.state.corporateMemberSubscription, this.state.corporateMemberSubscriptionValidationRule, this.state.validState);
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  }

  individualContributionValidate = (key, object) => {
    const returnValidState = validate(key, this.state.contributionDetails, this.state.individualContributionValidationRule, this.state.validState);
    this.setState({
      validState: returnValidState
    });
  }

  isValidindividualContribution = () => {
    const returnValidState = isValidForm(this.state.contributionDetails, this.state.individualContributionValidationRule, this.state.validState);
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  }

  committeInformationValidate = (key, object) => {
    const returnValidState = validate(key, this.state.committeInformation, this.state.committeInformationValidationRule, this.state.validState);
    this.setState({
      validState: returnValidState
    });
  }

  isValidcommitteInformation = () => {
    const returnValidState = isValidForm(this.state.committeInformation, this.state.committeInformationValidationRule, this.state.validState);
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  }

  addEditIndividualBasicInformation = () => {
    if (this.isValid()) {
      let request = this.state.individualBasicInformation;
      request.individualMemberId = this.state.individualMemberId;
      let domain = this.state.emailDomain.find(x => x.id === request.emailDomainId);
      request.email = `${request.emailText}@${domain.name}`
      this.individualMemberServices.addEditIndividualBasicInformation(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorType !== "EXISTS") {
          //this.getEngagementTrackingList(this.state.individualMemberId);
          this.swalServices.Success("Basic information inserted successfully.");
          this.setState({ individualMemberId: response.responseItem.responseContent.keyId })
        } else if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorType === "EXISTS") {
          this.swalServices.Error("Information with same email address is already exists.");

        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }

  addEditSIISInformation = () => {
    if (this.state.individualMemberId > 0) {
      if (this.isValidcorporateInformation()) {
        let request = this.state.sIISCorporateInformation;
        request.individualMemberId = this.state.individualMemberId;


        if (this.state.sIISCorporateInformation.selectedSGTech.length > 0) {
          var arr1 = this.state.sIISCorporateInformation.selectedSGTech.map(function (item) {
            delete item.bad;
            return item.value;
          });
          request.corporateSGTechId = arr1.join([',']);
        } else { request.corporateSGTechId = " " }

        if (this.state.sIISCorporateInformation.selectedSponsorship.length > 0) {
          var arr1 = this.state.sIISCorporateInformation.selectedSponsorship.map(function (item) {
            delete item.bad;
            return item.value;
          });
          request.corporateSponsorshipId = arr1.join([',']);
        } else { request.corporateSponsorshipId = " " }

        if (this.state.sIISCorporateInformation.selectedMatching.length > 0) {
          var arr1 = this.state.sIISCorporateInformation.selectedMatching.map(function (item) {
            delete item.bad;
            return item.value;
          });
          request.corporateMatchingId = arr1.join([',']);
        } else { request.corporateMatchingId = " " }

        if (this.state.sIISCorporateInformation.selectedProgram.length > 0) {
          var arr1 = this.state.sIISCorporateInformation.selectedProgram.map(function (item) {
            delete item.bad;
            return item.value;
          });
          request.corporateStrategicProgrammesId = arr1.join([',']);
        } else { request.corporateStrategicProgrammesId = " " }

        if (request.individualSIISAccountId === null) {
          request.individualSIISAccountId = 0;
        }
        this.individualMemberServices.addEditSIISInformation(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success("SIIS corporate information inserted successfully.");
          } else {
            this.swalServices.Error(response.message);
          }
        });
      }
    } else {
      this.swalServices.Warning("Please Enter Individual Basic Information");
    }
  }

  // addEditEngagementTracking = () => {
  //   if (this.state.individualMemberId > 0) {
  //     if (this.isValidengagement()) {
  //       
  //       let request = this.state.engagementTrackingDetails;
  //       request.individualMemberId = this.state.individualMemberId;
  //       this.individualMemberServices.addEditEngagementTracking(request).then((response) => {
  //         if (response.statusCode === 200 && response.responseItem != null) {
  //           this.getEngagementTrackingList(this.state.individualMemberId);

  //           this.resetEngagement();

  //           this.swalServices.Success("Engagement inserted successfully.");
  //         } else {
  //           this.swalServices.Error(response.message);
  //         }
  //       });
  //     }
  //   } else {
  //     this.swalServices.Warning("Please Enter Individual Basic Information");
  //   }
  // }



  // joinCommittee = () => {
  //   if (this.state.individualMemberId > 0) {
  //     let request = this.state.committeInformation;
  //     request.individualMemberId = this.state.individualMemberId;
  //     if (this.isValidcommitteInformation()) {
  //       this.individualMemberServices.joinCommittee(request).then((response) => {
  //         if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "Committee") {
  //           this.swalServices.Success("Committee already joined.");
  //           this.getJoinedCommittee(this.state.individualMemberId);
  //         } else if (response.statusCode === 200 && response.responseItem != null) {
  //           this.swalServices.Success("Committee joined successfully.");
  //           this.getJoinedCommittee(this.state.individualMemberId);
  //         } else {
  //           this.swalServices.Error(response.message);
  //         }
  //       });
  //     }
  //   } else {
  //     this.swalServices.Warning("Please Enter Individual Basic Information");
  //   }
  // }

  addEditMembershipSubscription = () => {
    if (this.state.individualMemberId > 0) {
      
      let request = this.state.corporateMemberSubscription;
      request.individualMemberId = this.state.individualMemberId;
      request.individualSubscriptionId = request.individualSubscriptionId ? request.individualSubscriptionId : 0;
      if (this.isValidCorporateMemberSubscription()) {
        // let request = this.state.corporateMemberSubscription;
        // request.individualMemberId = this.state.individualMemberId;
        this.individualMemberServices.addEditMemberSubscription(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success("Member Subscription inserted successfully.");
          } else {
            this.swalServices.Error(response.message);
          }
        });
      }
    } else {
      this.swalServices.Warning("Please Enter Individual Basic Information");
    }
  }

  saveContribution = (id) => {
    if (this.state.individualMemberId > 0) {
      if (this.isValidindividualContribution()) {
        if (id !== 0 && id === this.state.contributionDetails.individualContributionId) {
          let request = this.state.contributionDetails;
          request.individualMemberId = this.state.individualMemberId
          request.name = this.state.individualBasicInformation.firstName + " " + this.state.individualBasicInformation.lastName;
          this.individualMemberServices.saveContribution(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "ContributionSection") {
              this.swalServices.error(
                "Contribution details with same chapter or committee name already exist."
              );
              this.resetContributionDetails();
              this.getIndividualContribution(this.state.individualMemberId);
              this.setState({ editDetails: 0 });
            }
            else if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success("Contribution details updated successfully.");
              this.resetContributionDetails();
              this.getIndividualContribution(this.state.individualMemberId);
              this.setState({ editDetails: 0 });
            } else {
              this.swalServices.Error(response.message);
            }
          });
        }
        else if (id === 0) {
          let request = this.state.contributionDetails;
          request.individualMemberId = this.state.individualMemberId;
          request.individualContributionId = 0;
          request.name = this.state.individualBasicInformation.firstName + " " + this.state.individualBasicInformation.lastName;
          this.individualMemberServices.saveContribution(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "ContributionSection") {
              this.swalServices.error(
                "Contribution details with same chapter or committee name already exist."
              );
              this.resetContributionDetails();
              this.getIndividualContribution(this.state.individualMemberId);
              this.setState({ editDetails: 0 });
            }
            else if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success("Contribution details inserted successfully.");
              this.resetContributionDetails();
              this.getIndividualContribution(this.state.individualMemberId);
              this.setState({ addNew: false });
            } else {
              this.swalServices.Error(response.message);
            }
          });
        }
      }
    }
    else {
      this.swalServices.Warning("Please enter individual basic information");
    }
  };

  // getIndividualBasicInformation = (id) => {
  //   this.individualMemberServices.getBasicInformation(id).then((response) => {
  //     if (response.statusCode === 200 && response.responseItem != null) {
  //       this.setState({ individualBasicInformation: response.responseItem.responseContent })
  //       let details = response.responseItem.responseContent;
  //       var fields = details.email.split('@');
  //       details.emailText = fields[0];
  //       details.emailDomainId = details.corporateMemberId;
  //       this.setState({ individualBasicInformation: details });
  //       details.joinedDate = moment(details.joinedDate).format("DD-MM-yyyy");
  //     } else {
  //       this.swalServices.Error(response.message);
  //     }
  //   });
  // }

  // getSIISInformation = (id) => {
  //   this.individualMemberServices.getSIISInformation(id).then((response) => {
  //     if (response.statusCode === 200 && response.responseItem != null) {
  //       this.setState({ sIISCorporateInformation: response.responseItem.responseContent })

  //       var array = response.responseItem.responseContent.corporateSGTechId.split(',');
  //       var sgtech = [];
  //       array.forEach(topic => {
  //         sgtech.push(this.state.sgTech.find(topics => topics.value === Number(topic)));
  //       });

  //       array = response.responseItem.responseContent.corporateMatchingId ? response.responseItem.responseContent.corporateMatchingId.split(',') : [];
  //       var matching = [];
  //       array.forEach(topic => {
  //         matching.push(this.state.matching.find(topics => topics.value === Number(topic)));
  //       });

  //       array = response.responseItem.responseContent.corporateSponsorshipId ? response.responseItem.responseContent.corporateSponsorshipId.split(',') : [];
  //       var sponsor = [];
  //       array.forEach(topic => {
  //         sponsor.push(this.state.sponsorship.find(topics => topics.value === Number(topic)));
  //       });

  //       array = response.responseItem.responseContent.corporateStrategicProgrammesId ? response.responseItem.responseContent.corporateStrategicProgrammesId.split(',') : [];
  //       var program = [];
  //       array.forEach(topic => {
  //         program.push(this.state.programs.find(topics => topics.value === Number(topic)));
  //       });

  //       var profile = this.state.sIISCorporateInformation
  //       profile = response.responseItem.responseContent
  //       profile.selectedMatching = matching;
  //       profile.selectedSGTech = sgtech;
  //       profile.selectedProgram = program;
  //       profile.selectedSponsorship = sponsor;

  //       this.setState({ sIISCorporateInformation: { ...profile } })

  //       // sgTechArr: [],
  //       // sponsorshipArr: [],
  //       // matchingArr: [],
  //       // strategicProgrammesArr: [],

  //     } else {
  //       this.swalServices.Error(response.message);
  //     }
  //   });
  // }



  // getEngagementTrackingById = (id) => {
  //   
  //   this.individualMemberServices.getEngagmentDetailsById(id).then((response) => {
  //     if (response.statusCode === 200 && response.responseItem.responseContent != null) {
  //       this.setState({ engagementTrackingDetails: response.responseItem.responseContent })
  //     } else {
  //       //this.swalServices.Error(response.message);
  //     }
  //   });
  // }

  getJoinedCommittee = (id) => {
    this.individualMemberServices.getJoinedCommittee(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ selectedCommitteeList: response.responseItem.responseContent }, () => {
          var committes = response.responseItem.responseContent;
          let detail = this.state.committeInformation;
          if (committes.length > 0) {
            var arr1 = committes.map(function (item) {
              delete item.bad;
              return item.groupName;
            });
            detail.groupName = arr1.join([" , "]);

            this.setState({ committeInformation: detail });
          }
        });
      }
      else {
        this.swalServices.Error(response.message);
      }
    });
  }

  // deleteJoinedCommitees = (ids) => {
  //   this.individualMemberServices.deleteCommittees(ids).then((response) => {
  //     if (response.statusCode === 200 && response.responseItem != null) {
  //       this.swalServices.Success("Committee deleted successfully.");
  //       this.getJoinedCommittee(this.state.individualMemberId);
  //     } else {
  //       this.swalServices.Error(response.message);
  //     }
  //   });
  // }



  getMembershipSubscription = (id) => {
    this.individualMemberServices.getMembershipSubscription(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ corporateMemberSubscription: response.responseItem.responseContent })
      } else {
        this.swalServices.Error(response.message);
      }
    });
  }

  getIndividualContribution = (id) => {
    this.individualMemberServices.getIndividualContribution(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ individualContributionList: response.responseItem.responseContent });

      } else {
        this.swalServices.Error(response.message);
      }
    });
  };
  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
  };
  getAllDropDown = () => {
    this.individualMemberServices.getAllDropdownForIndividualMember().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        var role = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ContributionRoles");
        var role = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ContributionRole");
        var mrMrs = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "MrMrs");
        var organisation = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Organisation");
        var status = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "AccountStatus");
        var _By = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Users");
        var job = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "JobFunction");
        var jobLevel = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "JobLevel");
        var domain = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Domains");
        var chapters = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Chapters");
        var committee = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Committee");
        var membershipTypes = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "MembershipTypes");
        var deletionReasonList = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "DeletionReason");
        
        var parent = [];

        var groups = chapters.concat(committee);
        parent.push(...groups);
        // this.setState({ parentList: parent });

        // var sgtech = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "SGTech");
        // var sponsorship = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Sponsorship");
        // var matching = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Matching");
        // var program = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Programs");

        var domainArr = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Domains");
        var domainArr = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'Domains');
        domainArr.map(function (item) { delete item['dropdownName']; });
        domainArr = domainArr.map(p => ({
          id: p.id, name: "@" + p.name
        }));
        this.setState({ domainArr: domainArr }, () => this.setState({ emailDomain: domain }))

        var reason = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'SGTech');
        reason.map(function (item) { delete item['dropdownName']; });
        reason = reason.map(p => ({ value: p.id, label: p.name }));

        var sponsorship = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'Sponsorship');
        sponsorship.map(function (item) { delete item['dropdownName']; });
        sponsorship = sponsorship.map(p => ({
          value: p.id, label: p.name
        }));

        var matching = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'Matching');
        matching.map(function (item) { delete item['dropdownName']; });
        matching = matching.map(p => ({
          value: p.id, label: p.name
        }));

        var program = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'Programs');
        program.map(function (item) { delete item['dropdownName']; });
        program = program.map(p => ({
          value: p.id, label: p.name
        }));

        var engagement = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "EngagementType");
        var committee = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Committee");
        var recordtype = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "RecordType");
        var billingcategory = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "BillingCategory");
        var Companycategory = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CompanyCategory");
        var indi_status = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "IndividualStatus");

        this.setState({
          roles: role, mrMrs: mrMrs, organisation: organisation, status: status, _By: _By, jobFunction: job, jobLevel: jobLevel, reason: reason,
          sponsorship: sponsorship, matching: matching, programs: program, engagementType: engagement, committeeList: committee, recordType: recordtype, billingCategory: billingcategory,
          companyCategory: Companycategory, emailDomain: domain, individualStatus: indi_status, parentList: parent, membershipTypes: membershipTypes, chaptersList: chapters,
          deletionReasonList : deletionReasonList
        }, () => {
          if (this.state.individualMemberId) {
            this.getJoinedCommittee(this.state.individualMemberId)
          }
        });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  }

  resetContributionDetails = () => {
    let reset = this.state.contributionDetails;
    reset.individualContributionId = "";
    reset.name = "";
    reset.roleId = "";
    reset.startDate = "";
    reset.endDate = "";
    reset.contributionSection = "";

    this.setState({ contributionDetails: reset });
  };

  handleChange(event, identity) {
    const value = event.target.value;
    const name = event.target.name;

    if (identity === "EngagementTracking") {
      let detail = this.state.engagementTrackingDetails;
      detail[name] = value;
      this.setState({ engagementTrackingDetails: { ...detail } });
    }
    if (identity === "BasicInformation") {
      let detail = this.state.individualBasicInformation;
      detail[name] = value;
      if (name === "emailText") {
        detail["emailString"] = value + "@abc.com"
        this.validate("emailString", detail);
      }
      this.setState({ individualBasicInformation: { ...detail } });
    }
    if (identity === "SIISInformation") {
      let detail = this.state.sIISCorporateInformation;
      detail[name] = value;
      this.setState({ sIISCorporateInformation: { ...detail } });
    }
    if (identity === "MemebershipSubscription") {
      let detail = this.state.corporateMemberSubscription;
      detail[name] = value;
      this.setState({ corporateMemberSubscription: { ...detail } });
    }
    if (identity === "IndividualContribution") {
      let detail = this.state.contributionDetails;
      detail[name] = value;
      this.setState({ contributionDetails: { ...detail } });
    }
  }


  setFilterParameters = (id, drpIdentity) => {
    let engagement = this.state.engagementTrackingDetails;
    let basicInfo = this.state.individualBasicInformation;
    let siisInfo = this.state.sIISCorporateInformation
    let committee = this.state.committeInformation;
    let subscription = this.state.corporateMemberSubscription;
    let contribution = this.state.contributionDetails;

    if (drpIdentity === "EngagementType") {
      engagement["corporateEngagementTypeId"] = id;
      this.engagementValidate("corporateEngagementTypeId");
    }
    if (drpIdentity === "RecordedBy") {
      engagement["corporateEngagementRecordedById"] = id;
      this.engagementValidate("corporateEngagementRecordedById");
    }
    if (drpIdentity === "FollowUpBy") {
      engagement["corporateEngagementFollowUpById"] = id;
      this.engagementValidate("corporateEngagementFollowUpById");
    }
    if (drpIdentity === "MrMrs") {
      basicInfo["mrMrsId"] = id;
      //this.validateField("membershipTypeId");
    }
    if (drpIdentity === "Organisation") {
      basicInfo["corporateMemberId"] = id;
      this.getDomainByOrganisationId(id);
      //this.validateField("membershipTypeId");
    }
    // if (drpIdentity === "EmailDomain") {
    //   basicInfo["emailDomainId"] = id;
    //   this.validate("emailDomainId", this.state.individualBasicInformation);
    // }
    if (drpIdentity === "IndividualStatus") {
      basicInfo["individualStatusId"] = id;
      //this.validateField("individualStatusId");
      //this.validateField("individualStatusId");
    }
    if (drpIdentity === "isAccountActiveStatus") {
      siisInfo["isAccountActiveStatus"] = id;
      this.corporateInformationValidate("isAccountActiveStatus");
    }
    if (drpIdentity === "updatedbyId") {
      siisInfo["updatedbyId"] = id;
      this.corporateInformationValidate("updatedbyId");
    }
    if (drpIdentity === "employmentJobFunctionId") {
      siisInfo["employmentJobFunctionId"] = id;
      this.corporateInformationValidate("employmentJobFunctionId");
    }
    if (drpIdentity === "employmentJobLevelId") {
      siisInfo["employmentJobLevelId"] = id;
      this.corporateInformationValidate("employmentJobLevelId");
    }
    if (drpIdentity === "corporateSGTechId") {
      siisInfo["corporateSGTechId"] = id;
      this.corporateInformationValidate("corporateSGTechId");
    }
    if (drpIdentity === "corporateSponsorshipId") {
      siisInfo["corporateSponsorshipId"] = id;
      this.corporateInformationValidate("corporateSponsorshipId");
    }
    if (drpIdentity === "corporateMatchingId") {
      siisInfo["corporateMatchingId"] = id;
      this.corporateInformationValidate("corporateMatchingId");
    }
    if (drpIdentity === "corporateStrategicProgrammesId") {
      siisInfo["corporateStrategicProgrammesId"] = id;
      this.corporateInformationValidate("corporateStrategicProgrammesId");
    }
    if (drpIdentity === "workgroupMappingId") {
      committee["workgroupMappingId"] = id;
      this.committeInformationValidate("workgroupMappingId");
    }
    if (drpIdentity === "corporateMemberRecordTypeId") {
      subscription["corporateMemberRecordTypeId"] = id;
      this.isValidCorporateMemberSubscription("corporateMemberRecordTypeId");
    }
    if (drpIdentity === "companyCategoryId") {
      subscription["companyCategoryId"] = id;
      this.isValidCorporateMemberSubscription("companyCategoryId");
    }
    if (drpIdentity === "corporateMemberBillingCategoryId") {
      subscription["corporateMemberBillingCategoryId"] = id;
      this.isValidCorporateMemberSubscription("corporateMemberBillingCategoryId");
    }
    if (drpIdentity === "isSubscriptionAccountStatus") {
      subscription["isSubscriptionAccountStatus"] = id;
      this.isValidCorporateMemberSubscription("isSubscriptionAccountStatus");
    }
    if (drpIdentity === "updatedById_Subscription") {
      subscription["updatedById"] = id;
      this.isValidCorporateMemberSubscription("updatedById");
    }
    if (drpIdentity === "Role") {
      contribution["roleId"] = id;
      this.isValidCorporateMemberSubscription("roleId");
      this.individualContributionValidate("roleId");
    }
    if (drpIdentity === "Chapter/Commitee") {
      contribution["workgroupMappingId"] = id;
      this.individualContributionValidate("workgroupMappingId");
    }

    this.setState({ engagementTrackingDetails: engagement });
    this.setState({ individualBasicInformation: basicInfo });
    this.setState({ sIISCorporateInformation: siisInfo });
    this.setState({ committeInformation: committee });
    this.setState({ contributionDetails: contribution });
  };

  handleChangeMultiDropdown(selected, identity) {

    if (identity === "sgtech") {
      var profile = this.state.sIISCorporateInformation;
      profile.selectedSGTech = selected
      this.setState({ sIISCorporateInformation: profile });
      // this.validateField("optionSelected");
    }
    else if (identity === "sponsorship") {
      var profile = this.state.sIISCorporateInformation;
      profile.selectedSponsorship = selected
      this.setState({ sIISCorporateInformation: profile });
      // this.validateField("optionSelected");
    }
    else if (identity === "matching") {
      var profile = this.state.sIISCorporateInformation;
      profile.selectedMatching = selected
      this.setState({ sIISCorporateInformation: profile });
      // this.validateField("optionSelected");
    }
    else if (identity === "program") {
      var profile = this.state.sIISCorporateInformation;
      profile.selectedProgram = selected
      this.setState({ sIISCorporateInformation: profile });
      // this.validateField("optionSelected");
    }
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let individualId = Number(params.get(""));
    this.getAllDropDown();


    if (individualId != null && individualId > 0) {
      this.setState({ individualMemberId: individualId },
        () => this.getIndividualSIISInformation(this.state.individualMemberId));
    }
    // this.setState({ individualMemberId: individualId },
    //   () => {


    //     // if (individualId > 0 && individualId !== null) {
    //     //   this.getEngagementTrackingList(individualId);
    //     //   this.getIndividualBasicInformation(individualId);
    //     //   this.getSIISInformation(individualId);
    //     //   // this.getJoinedCommittee(individualId);
    //     //   this.getMembershipSubscription(individualId);
    //     //   this.getIndividualContribution(individualId);
    //     // }
    //   })

  }

  /// Akshay new API 27/7/2022
  addEditIndividualSIISInformation = () => {
    if (this.isValidSIIS()) {
      let request = this.state.siisIndividualAccountInfo;
      request.individualMemberId = this.state.individualMemberId;
      
      this.individualMemberServices.addEditIndividualSIISInformation(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.keyId !== this.state.individualMemberId) {
            this.setState({ individualMemberId: response.responseItem.responseContent.keyId }, () => this.getIndividualSIISInformation(this.state.individualMemberId))
          }
          this.swalServices.Success("SIIS information inserted successfully.");
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }
  addEditEngagementTracking = () => {
    if (this.isValidIndividualEngagement()) {
      let request = this.state.engagementTrackingAndNotes;
      request.individualMemberId = this.state.individualMemberId;
      this.individualMemberServices.addEditEngagementTracking(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Engagement inserted successfully.");
          this.getEngagementTrackingList(this.state.individualMemberId);
          this.resetEngagement();
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }

  }
  addEditIndividualCommittee = () => {
    if (this.isValidCommitteeCard()) {
      let request = this.state.committeeCard;
      request.individualMemberId = this.state.individualMemberId;

      this.individualMemberServices.addEditIndividualCommittee(request).then((response) => {
        
        if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "Committee") {
          this.swalServices.Error("Committee already joined.");
          this.getIndividualCommittee(this.state.individualMemberId);
        } else if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Committee joined successfully.");
          this.getIndividualCommittee(this.state.individualMemberId);
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }

  }
  addEditIndividualContribution = () => {
    if (this.isValidIndividualContribution()) {
      let request = this.state.individualContribution;
      request.individualMemberId = this.state.individualMemberId;
      request.name = this.state.individualProfile.firstName + " " + this.state.individualProfile.lastName;
      
      this.individualMemberServices.addEditIndividualContribution(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          // this.getEngagementTrackingList(this.state.individualMemberId);

          this.swalServices.Success("Individual contribution inserted successfully.");
          this.getIndividualContribution(this.state.individualMemberId);
          this.setState({ editDetails: 0 });
          this.setState({ addNew: false });

        } else {
          this.swalServices.Error(response.message);
        }
        // if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "ContributionSection") {
        //   this.swalServices.error(
        //     "Contribution details with same chapter or committee name already exist."
        //   );
        //   this.setState({ editDetails: 0 });
        //   this.getIndividualContribution(this.state.individualMemberId);
        // }
        // else if (response.statusCode === 200 && response.responseItem != null) {
        //   this.swalServices.Success("Contribution details inserted successfully.");

        //   this.setState({ addNew: false });
        //   this.getIndividualContribution(this.state.individualMemberId);
        // } else {
        //   this.swalServices.Error(response.message);
        // }
      });
    }

  }
  addEditIndividualProfile = () => {
    if (this.isValidIndividualProfile()) {
      let request = this.state.individualProfile;
      request.individualMemberId = this.state.individualMemberId;
      //request.individualMemberId = this.state.individualMemberId;
      let domain = this.state.emailDomain.find(x => x.id === request.emailDomainId);
      request.email = `${request.emailText}@${domain.name}`
      
      this.individualMemberServices.addEditIndividualProfile(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "Email") {
          this.swalServices.Error("Email address already exists.");
        } else if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Individual profile details updated successfully");

        }
        else {
          this.swalServices.Error(response.message);
        }
      });
    }

  }
  addEditEmploymentInformation = () => {
    if (this.isValidIndividualEmployment()) {
      let request = this.state.employmentInformation;
      request.individualMemberId = this.state.individualMemberId;
      
      this.individualMemberServices.addEditEmploymentInformation(request).then((response) => {
        
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("employment information updated successfully.");
          let detail = this.state.employmentInformation;
          detail.individualEmploymentId = response.responseItem.responseContent.keyId;
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }

  }
  addEditSGTechInterestProfile = () => {

    let request = this.state.sgtechInterest;
    request.individualMemberId = this.state.individualMemberId;
    if (this.state.sgtechInterest.selectedReason.length > 0) {
      var arr1 = this.state.sgtechInterest.selectedReason.map(function (item) {
        delete item.bad;
        return item.value;
      });
      request.corporateReasonId = arr1.join([',']);
    } else { request.corporateReasonId = " " }

    if (this.state.sgtechInterest.selectedSponsorship.length > 0) {
      var arr1 = this.state.sgtechInterest.selectedSponsorship.map(function (item) {
        delete item.bad;
        return item.value;
      });
      request.corporateSponsorshipId = arr1.join([',']);
    } else { request.corporateSponsorshipId = " " }

    if (this.state.sgtechInterest.selectedMatching.length > 0) {
      var arr1 = this.state.sgtechInterest.selectedMatching.map(function (item) {
        delete item.bad;
        return item.value;
      });
      request.corporateMatchingId = arr1.join([',']);
    } else { request.corporateMatchingId = " " }

    if (this.state.sgtechInterest.selectedProgram.length > 0) {
      var arr1 = this.state.sgtechInterest.selectedProgram.map(function (item) {
        delete item.bad;
        return item.value;
      });
      request.corporateStrategicProgrammesId = arr1.join([',']);
    } else { request.corporateStrategicProgrammesId = " " }

    

    this.individualMemberServices.addEditSGTechInterestProfile(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.Success("Individual SGTech interest updated successfully.");
      } else {
        this.swalServices.Error(response.message);
      }
    });
  }
  addEditCommunicationProfile = () => {
    let request = this.state.sgtechCommunication;
    request.individualMemberId = this.state.individualMemberId;
    
    this.individualMemberServices.addEditCommunicationProfile(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.Success("Individual communication profile updated successfully.");
      } else {
        this.swalServices.Error(response.message);
      }
    });
  }

  // Get all segments
  getIndividualSIISInformation = (id) => {
    if (id > 0) {
      this.individualMemberServices.getIndividualSIISInformation(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {

          this.setState({
            siisIndividualAccountInfo: response.responseItem.responseContent,
            corporateMemberId: response.responseItem.responseContent.corporateMemberId
          })

        } else {
          this.swalServices.Error(response.message);
        }
      });
    }

  }
  getIndividualCommittee = (id) => {
    if (id > 0) {

      this.individualMemberServices.getIndividualCommittee(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({ selectedCommitteeList: response.responseItem.responseContent }, () => {
            var committes = response.responseItem.responseContent;
            let detail = this.state.committeeCard;
            if (committes.length > 0) {
              var arr1 = committes.map(function (item) {
                delete item.bad;
                return item.groupName;
              });
              detail.groupName = arr1.join([" , "]);

              this.setState({ committeeCard: detail });
            }
          });

        }
        else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }
  getIndividualContributionList = (id) => {
    if (id > 0) {
      this.individualMemberServices.getIndividualContributionList(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          
          this.setState({ individualContributionList: response.responseItem.responseContent })

        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }
  getIndividualSGTechInterest = (id) => {
    if (id > 0) {
      this.individualMemberServices.getIndividualSGTechInterest(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          
          this.setState({ sgtechInterest: response.responseItem.responseContent })

          var array = response.responseItem.responseContent.corporateReasonId.split(',');
          var reason = [];
          array.forEach(topic => {
            reason.push(this.state.reason.find(topics => topics.value === Number(topic)));
          });

          array = response.responseItem.responseContent.corporateMatchingId ? response.responseItem.responseContent.corporateMatchingId.split(',') : [];
          var matching = [];
          array.forEach(topic => {
            matching.push(this.state.matching.find(topics => topics.value === Number(topic)));
          });

          array = response.responseItem.responseContent.corporateSponsorshipId ? response.responseItem.responseContent.corporateSponsorshipId.split(',') : [];
          var sponsor = [];
          array.forEach(topic => {
            sponsor.push(this.state.sponsorship.find(topics => topics.value === Number(topic)));
          });

          array = response.responseItem.responseContent.corporateStrategicProgrammesId ? response.responseItem.responseContent.corporateStrategicProgrammesId.split(',') : [];
          var program = [];
          array.forEach(topic => {
            program.push(this.state.programs.find(topics => topics.value === Number(topic)));
          });

          var profile = this.state.sgtechInterest;
          profile = response.responseItem.responseContent
          profile.selectedMatching = matching;
          profile.selectedReason = reason;
          profile.selectedProgram = program;
          profile.selectedSponsorship = sponsor;


        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }
  getIndividualCommunication = (id) => {
    if (id > 0) {
      this.individualMemberServices.getIndividualCommunication(id).then((response) => {
        
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            this.setState({ sgtechCommunication: response.responseItem.responseContent })

          }

        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }
  getIndividualEngagementTrackingById = (id) => {
    this.individualMemberServices.getIndividualEngagementTrackingById(id).then((response) => {
      
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        this.setState({ engagementTrackingAndNotes: response.responseItem.responseContent })
      } else {
        this.swalServices.Error(response.message);
      }
    });
  }
  getDomainByOrganisationId = (id) => {
    // var domainId = this.state.emailDomain.filter(domain => domain.id === id);
    // this.setState({ emailDomain: domainId });

    var domainId = this.state.emailDomain.find(domain => domain.id === id);
    let setDomain = this.state.individualProfile;
    setDomain.emailDomainId = domainId.id;
    this.setState({ individualProfile: setDomain });
  }
  getEngagementTrackingList = (id) => {
    if (id > 0) {
      this.individualMemberServices.getEngagementTrackingList(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({ engagemmentTrackingList: response.responseItem.responseContent })

        } else {
          this.swalServices.Error(response.message);
        }
      });
    }

  }
  getIndividualProfileDetails = (id) => {
    if (id > 0) {
      this.individualMemberServices.getIndividualProfileDetails(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent;
          var fields = details.email.split('@');
          details.emailText = fields[0];
          details.emailDomainId = details.corporateMemberId;
          this.setState({ individualProfile: response.responseItem.responseContent })

        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }
  getEmploymentInformation = (id) => {
    if (id > 0) {
      this.individualMemberServices.getEmploymentInformation(id).then((response) => {
        if (response.statusCode === 200 && response != null) {
          if (response.responseItem.responseContent != null) {
            this.setState({ employmentInformation: response.responseItem.responseContent })
          }

        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }

  getChapterInformationById = () => {
    if (this.state.corporateMemberId != null && this.state.corporateMemberId > 0) {
      this.individualMemberServices.getChapterInformationById(this.state.corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          // let res = { ...this.state.chapterInformation };
          // res.corporateChapterId = response.responseItem.responseContent.corporateChapterId;
          // res.workgroupMappingId = response.responseItem.responseContent.workgroupMappingId;
          // res.groupName = response.responseItem.responseContent.groupName;
          // res.joinDate = response.responseItem.responseContent.joinDate;
          // res.leftDate = response.responseItem.responseContent.leftDate;
          // res.updatedBy = response.responseItem.responseContent.updatedBy;
          // res.lastUpdatedDate = response.responseItem.responseContent.lastUpdatedDate;
          this.setState({ chapterList: response.responseItem.responseContent });
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }

  }

  getCorporateMembershipSubscriptionById = () => {
    if (this.state.corporateMemberId != null && this.state.corporateMemberId > 0) {

      this.individualMemberServices.getCorporateMembershipSubscriptionById(this.state.corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.membershipSubscription };
            res.corporateMemberSubscriptionId = response.responseItem.responseContent.corporateMemberSubscriptionId;
            res.membershipTypeId = response.responseItem.responseContent.membershipTypeId;
            res.billingCategoryId = response.responseItem.responseContent.billingCategoryId;
            res.subscriptionStartDate = response.responseItem.responseContent.subscriptionStartDate;
            res.subcriptionEndDate = response.responseItem.responseContent.subcriptionEndDate;
            res.updatedBy = response.responseItem.responseContent.updatedBy;
            res.updatedAt = response.responseItem.responseContent.updatedAt;
            this.setState({ membershipSubscription: res });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }


  // Delete Committees
  deleteCommittees = (id) => {
    let request = {
      deletedBy: 1,
      appId: 51,
      ids: id,
    }
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Chapter"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Chapter"), CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
      if (response) {
        this.individualMemberServices.deleteCommittees(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.getJoinedCommittee(this.state.individualMemberId);
          } else {
            this.swalServices.Error(response.message);
          }
        })
      }
    })
  }

  // Save image
  saveMainImage = (file, keyId, imageIdentity) => {
    file.storagePath = imageIdentity;

    this.individualMemberServices.storeBase64Image(file).then((response) => {
      
      if (response.fileName != null) {
        let request = this.state.individualProfile;
        request.photo = response.fileName;
        this.setState({ individualProfile: request });


      } else {
        this.swalServices.Error(response.message);
      }
    });
  };


  // Edit enagagement Tracking list
  onEdit = (id) => {
    let reset = this.state.validStateEngagement;
    reset.error = {}
    this.setState({ validStateEngagement: reset })
    this.getIndividualEngagementTrackingById(id);
  }
  editContribution = (id, data) => {
    this.setState({ editDetails: id });
    this.setState({ addNew: false });

    let details = this.state.individualContribution;
    details.individualContributionId = data.individualContributionId;
    details.startDate = data.startDate;
    details.endDate = data.endDate;
    details.name = data.name;
    details.roleId = data.roleId;
    details.individualMemberId = data.individualMemberId;

    this.setState({ contributionDetails: details });
  };

  // rest engagement
  resetEngagement() {
    let details = this.state.engagementTrackingAndNotes;
    details.corporateEngagementTypeId = 0;
    details.engagementDate = "";
    details.engagementDescription = "";
    details.corporateEngagementRecordedById = 0;
    details.corporateEngagementFollowUpById = 0;
    details.individualEngagementId = 0;
    this.setState({ engagementTrackingAndNotes: details })
  }


  // call API with click of segments

  openSection = (section) => {
    this.setState({
      openSection: this.state.openSection === section ? "" : section,
    });

    if (section === "SIISIndividualAccountInformation") {
      this.getIndividualSIISInformation(this.state.individualMemberId);
    } else if (section === "EngagementTracking") {
      this.getEngagementTrackingList(this.state.individualMemberId);
    } else if (section === "CorporateMembershipSubscription") {
      this.getCorporateMembershipSubscriptionById(this.state.corporateMemberId);
    } else if (section === "CorporateAccountInformation") {
      this.getChapterInformationById(this.state.corporateMemberId);
    } else if (section === "CommitteeInformation") {
      this.getIndividualCommittee(this.state.individualMemberId);
    } else if (section === "IndividualBasicInformation") {
      this.getIndividualProfileDetails(this.state.individualMemberId);
    } else if (section === "EmploymentInformation") {
      this.getEmploymentInformation(this.state.individualMemberId);
    } else if (section === "IndividualContribution") {
      this.getIndividualContributionList(this.state.individualMemberId);
    } else if (section === "SGTechInterestCard") {
      this.getIndividualSGTechInterest(this.state.individualMemberId);
    } else if (section === "CommunicationCard") {
      this.getIndividualCommunication(this.state.individualMemberId)
    }

  };


  // Handle Change for multi select dropdown
  handleChangeMultiDropdownInterest(selected, identity) {

    var profile = this.state.sgtechInterest;
    if (identity === "reason") {
      profile.selectedReason = selected
    }
    else if (identity === "sponsorship") {
      profile.selectedSponsorship = selected
    }
    else if (identity === "matching") {
      profile.selectedMatching = selected
    }
    else if (identity === "program") {
      profile.selectedProgram = selected
    }
    this.setState({ sgtechInterest: profile });

  }


  // handle change for checkbox
  handleChangeCheckBox = (e) => {
    const { name, checked } = e.target;
    let det = this.state.sgtechCommunication;
    det[name] = checked;
    this.setState({ sgtechCommunication: { ...det } });
    
  }
  handleChangeCheckBoxContribution = (e) => {
    const { name, checked } = e.target;
    let det = this.state.individualContribution;
    det[name] = checked;
    this.setState({ individualContribution: { ...det } });
  }
  handleChangeCheckBoxSIIS = (e) => {
    const { name, checked } = e.target;
    let det = this.state.siisIndividualAccountInfo;
    det[name] = checked;
    this.setState({ siisIndividualAccountInfo: { ...det } });
  }


  // handle change for text input
  handleChangeEngagement(event, identity) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.engagementTrackingAndNotes;
    detail[name] = value;
    this.setState({ engagementTrackingAndNotes: { ...detail } });
  }
  handleChangeContribution(event, identity) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.individualContribution;
    detail[name] = value;
    this.setState({ individualContribution: { ...detail } });
  }
  handleChangeProfile(event, identity) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.individualProfile;
    detail[name] = value;
    if (name === "emailText") {
      detail["emailString"] = value + "@abc.com"
      this.validateIndividualProfile("emailString", detail);
    }
    this.setState({ individualProfile: { ...detail } });
  }
  handleChangeEmployment(event, identity) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.employmentInformation;
    detail[name] = value;
    this.setState({ employmentInformation: { ...detail } });
  }
  handleChangeSIIS(event, identity) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.siisIndividualAccountInfo;
    detail[name] = value;
    this.setState({ siisIndividualAccountInfo: { ...detail } });
  }


  // set filter parameter for single select dropdown
  setFilterParametersEngagement(id, drpIdentity) {
    let engagement = this.state.engagementTrackingAndNotes;
    if (drpIdentity === "EngagementType") {
      engagement["corporateEngagementTypeId"] = id;
      this.validateIndividualEngagement("corporateEngagementTypeId");
    }
    if (drpIdentity === "RecordedBy") {
      engagement["corporateEngagementRecordedById"] = id;
      this.validateIndividualEngagement("corporateEngagementRecordedById");

    }
    if (drpIdentity === "FollowUpBy") {
      engagement["corporateEngagementFollowUpById"] = id;
    }
    this.setState({ engagementTrackingAndNotes: engagement });


  }
  setFilterParametersContribution(id, drpIdentity) {
    let details = this.state.individualContribution;
    if (drpIdentity === "Role") {
      details["roleId"] = id;
      this.validateIndividualContribution("roleId");
    }
    if (drpIdentity === "Chapter/Commitee") {
      details["workgroupMappingId"] = id;
      this.validateIndividualContribution("workgroupMappingId");
    }
    this.setState({ individualContribution: details });


  }
  setFilterParametersCommittee(id, drpIdentity) {
    let details = this.state.committeeCard;
    if (drpIdentity === "workgroupMappingId") {
      details["workgroupMappingId"] = id;
      this.validateCommitteeCard("workgroupMappingId")
    }
    this.setState({ committeeCard: details });
  }
  setFilterParametersProfile(id, drpIdentity) {
    let engagement = this.state.individualProfile;
    if (drpIdentity === "mrMrsId") {
      engagement["mrMrsId"] = id;
      this.validateIndividualProfile("mrMrsId");
    }
    if (drpIdentity === "Organisation") {
      engagement["corporateMemberId"] = id;
      this.validateIndividualProfile("corporateMemberId");
      this.getDomainByOrganisationId(id);
    }
    // if (drpIdentity === "EmailDomain") {
    //   engagement["emailDomainId"] = id;
    // }
    if (drpIdentity === "IndividualStatus") {
      engagement["individualStatusId"] = id;
      this.validateIndividualProfile("individualStatusId");

    }

    this.setState({ individualProfile: engagement });
  }
  setFilterParametersEmployment(id, drpIdentity) {
    let details = this.state.employmentInformation;
    if (drpIdentity === "employmentJobFunctionId") {
      details["employmentJobFunctionId"] = id;
      this.validateIndividualEmployment("employmentJobFunctionId");
    }
    if (drpIdentity === "employmentJobLevelId") {
      details["employmentJobLevelId"] = id;
      this.validateIndividualEmployment("employmentJobLevelId");

    }
    this.setState({ employmentInformation: details });
  }

  setFilterParametersSIIS(id, drpIdentity) {
    let details = this.state.siisIndividualAccountInfo;
    if (drpIdentity === "accountStatus") {
      details["accountStatus"] = id;
      this.validateSIISIndividualAccount("accountStatus");
    }
    if (drpIdentity === "updatedBy") {
      details["updatedBy"] = id;
      this.validateSIISIndividualAccount("updatedBy");
    }
    if (drpIdentity === "DeletionReasonId") {
      details["deletionReasonId"] = id;
    }

    this.setState({ siisIndividualAccountInfo: details });
  }



  render() {
    return (
      <div className="main-body-section">

        {/* Account Information Card Start*/}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="accountInformation"
              type="checkbox"
              name="corporate-d"
              value={"SIISIndividualAccountInformation"}
              checked={this.state.openSection === "SIISIndividualAccountInformation"}
              onClick={() => this.openSection("SIISIndividualAccountInformation")}

            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="accountInformation"
            >
              <span className="small-title font-bold theme-color pl-5">
                SIIS Individual Account Information 
              </span>
              <div className="flex items-left justify-between text-[18px] text-[#757575]">
                <p>SIIS Individual ID: {this.state.siisIndividualAccountInfo.sIISIndividualId} | Engagement Score: {this.state.siisIndividualAccountInfo.engagementScore}</p>
              </div>
            </label>
            <div className="tab-content overflow-hidden leading-normal px-5">
              <AccountInformation
                status={this.state.status}
                _By={this.state._By}
                jobFunction={this.state.jobFunction}
                jobLevel={this.state.jobLevel}


                //addEditSIISInformation={this.addEditSIISInformation.bind(this)}



                // new 
                siisIndividualAccountInfo={this.state.siisIndividualAccountInfo}
                addEditIndividualSIISInformation={this.addEditIndividualSIISInformation.bind(this)}
                handleChangeCheckBox={this.handleChangeCheckBoxSIIS.bind(this)}
                handleChange={this.handleChangeSIIS.bind(this)}
                setFilterParameters={this.setFilterParametersSIIS.bind(this)}
                validState={this.state.validStateSIIS}
                validate={this.validateSIISIndividualAccount.bind(this)}
                deletionReasonList={this.state.deletionReasonList}




              />
            </div>
          </div>
        </div>

        {/* Engagement Tracking and Notes Start */}

        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="engage"
              type="checkbox"
              name="corporate-d"
              value={"EngagementTracking"}
              checked={this.state.openSection === "EngagementTracking"}
              onClick={() => this.openSection("EngagementTracking")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="engage"
            >
              <span className="small-title font-bold theme-color pl-5">
                Engagement Tracking and Notes
              </span>
            </label>

            <div className="tab-content overflow-hidden leading-normal px-5">
              <EngagementTrackingNotes
                engagementType={this.state.engagementType}
                _By={this.state._By}
                engagementList={this.state.engagemmentTrackingList}
                //onEdit={this.onEdit.bind(this)}
                //onClick={this.addEditEngagementTracking.bind(this)}
                engagementTrackingDetails={this.state.engagementTrackingDetails}
                //handleChange={this.handleChange.bind(this)}
                //setFilterParameters={this.setFilterParameters.bind(this)}

                isCorporate={false}


                // new 
                engagementTrackingAndNotes={this.state.engagementTrackingAndNotes}
                onClick={this.addEditEngagementTracking.bind(this)}
                handleChange={this.handleChangeEngagement.bind(this)}
                onEdit={this.onEdit.bind(this)}
                setFilterParameters={this.setFilterParametersEngagement.bind(this)}
                validState={this.state.validStateEngagement}
                validate={this.validateIndividualEngagement.bind()}

              />
            </div>
          </div>
        </div>


        {/* Corporate Membership Subscription Start */}

        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="coporatem-sub"
              type="checkbox"
              name="corporate-d"
              value={" CorporateMembershipSubscription"}
              checked={this.state.openSection === "CorporateMembershipSubscription"}
              onClick={() => this.openSection("CorporateMembershipSubscription")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="coporatem-sub"
            >
              <span className="small-title font-bold theme-color pl-5">
                Corporate Membership Subscription
              </span>
            </label>

            <div className="tab-content overflow-hidden leading-normal px-5">
              {/* <CorporateMembershipSubscription
                recordType={this.state.recordType}
                companyCategory={this.state.companyCategory}
                billingcategory={this.state.billingCategory}
                status={this.state.status}
                _By={this.state._By}
                addEditMembershipSubscription={this.addEditMembershipSubscription.bind(this)}
                setFilterParameters={this.setFilterParameters.bind(this)}
                corporateMemberSubscription={this.state.corporateMemberSubscription}
                handleChange={this.handleChange.bind(this)}
                validState={this.state.validState}
                validate={this.corporateMemberSubscriptiontValidate.bind()}
              /> */}
              <CorporateMembershipSubscription
                _by={this.state._By}
                billingCategory={this.state.billingCategory}
                membershipTypes={this.state.membershipTypes}
                membershipSubscription={this.state.membershipSubscription}
                validState={this.state.validState}
                setFilterParameters={this.setFilterParameters.bind(this)}
              // onSave={this.addEditCorporateMembershipSubscription.bind(this)}
              // handleChange={this.handleChange.bind(this)}
              // validate={this.corporateInformationValidate.bind()}
              // handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
              />
            </div>
          </div>
        </div>


        {/* SIIS Corporate Account Information  Start*/}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="chapterInforamtion"
              type="checkbox"
              name="corporate-d"
              value={" CorporateAccountInformation"}
              checked={this.state.openSection === "CorporateAccountInformation"}
              onClick={() => this.openSection("CorporateAccountInformation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="chapterInforamtion"
            >
              <span className="small-title font-bold theme-color pl-5">
                Corporate Chapter Information
              </span>
            </label>

            <div className="tab-content overflow-hidden leading-normal px-5">
              <CorporateChapterInformation
                chapterList={this.state.chapterList}
                chaptersList={this.state.chaptersList}
                chapterInformation={this.state.chapterInformation}
                availableChapters={this.state.availableChapters}
                // deleteChapter={this.deleteChapter.bind()}
                //save={this.joinChapterById.bind(this)}
                setFilterParameters={this.setFilterParameters.bind(this)}
              />

              {/* <ChapterInformation
                chapterList={this.state.chapterList}
                chaptersList={this.state.chaptersList}
                chapterInformation={this.state.chapterInformation}
                pagination={this.state.pagination}
                sortingColumns={[""]}
                isLoading={this.state.isLoading}
                totalResultes={this.state.totalResultes}
                totalCount={this.state.totalResultes}
                availableChapters={this.state.availableChapters}
                deleteChapter={this.deleteChapter.bind()}
                save={this.joinChapterById.bind(this)}
                setFilterParameters={this.setFilterParameters.bind(this)}
              /> */}
            </div>
          </div>
        </div>



        {/* Committee Information Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="commitee"
              type="checkbox"
              name="corporate-d"
              value={" CommitteeInformation"}
              checked={this.state.openSection === "CommitteeInformation"}
              onClick={() => this.openSection("CommitteeInformation")}
            />

            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="commitee"
            >
              <span className="small-title font-bold theme-color pl-5">
                Committee Card
              </span>
            </label>

            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <CommitteeInformation
                committeeList={this.state.committeeList}
                selectedCommitteeList={this.state.selectedCommitteeList}
                committeInformation={this.state.committeInformation}

                setPagination={this.state.setPagination}
                deleteCommittees={this.deleteCommittees.bind(this)}

                // new 
                committeeCard={this.state.committeeCard}
                joinCommitee={this.addEditIndividualCommittee.bind(this)}
                setFilterParameters={this.setFilterParametersCommittee.bind(this)}
                validState={this.state.validStateCommittee}



              />
            </div>
          </div>
        </div>


        {/* Individual Basic Information Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="basicinfo"
              type="checkbox"
              name="corporate-d"
              value={"IndividualBasicInformation"}
              checked={this.state.openSection === "IndividualBasicInformation"}
              onClick={() => this.openSection("IndividualBasicInformation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="basicinfo"
            >
              <span className="small-title font-bold theme-color pl-5">
                Individual Profile
              </span>
            </label>
            <div className="tab-content overflow-hidden leading-normal px-5">
              <IndividualBasicInformation
                mrMrs={this.state.mrMrs}
                organisation={this.state.organisation}
                emailDomain={this.state.emailDomain}
                domainArr={this.state.domainArr}
                status={this.state.status}
                individualBasicInformation={this.state.individualBasicInformation}
                //handleChange={this.handleChange.bind(this)}
                individualStatus={this.state.individualStatus}
                emailHistory={this.state.emailHistory}

                // new 

                individualProfile={this.state.individualProfile}
                handleChange={this.handleChangeProfile.bind(this)}
                onSave={this.addEditIndividualProfile.bind(this)}
                setFilterParameters={this.setFilterParametersProfile.bind(this)}
                validate={this.validateIndividualProfile.bind(this)}
                validState={this.state.validStateIndProfile}
                saveMainImage={this.saveMainImage.bind(this)}




              />
            </div>
          </div>
        </div>

        {/* Employment Information Card Start*/}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="employmentInformation"
              type="checkbox"
              name="corporate-d"
              value={" EmploymentInformation"}
              checked={this.state.openSection === "EmploymentInformation"}
              onClick={() => this.openSection("EmploymentInformation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="employmentInformation"
            >
              <span className="small-title font-bold theme-color pl-5">
                Employment Information
              </span>
            </label>
            <div className="tab-content overflow-hidden leading-normal px-5">
              <EmploymentInformation


                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}

                // new 
                jobFunction={this.state.jobFunction}
                jobLevel={this.state.jobLevel}
                employmentInformation={this.state.employmentInformation}
                setFilterParameters={this.setFilterParametersEmployment.bind(this)}
                handleChange={this.handleChangeEmployment.bind(this)}
                addEditEmploymentInformation={this.addEditEmploymentInformation.bind(this)}
                validState={this.state.validStateEmpInfo}
                validate={this.validateIndividualEmployment.bind(this)}



              />
            </div>
          </div>
        </div>


        {/* Individual Contribution Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="individual-co"
              type="checkbox"
              name="corporate-d"
              value={"IndividualContribution"}
              checked={this.state.openSection === "IndividualContribution"}
              onClick={() => this.openSection("IndividualContribution")}
            />

            <label
              className="heading-part px-7 py-2 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="individual-co"
            >
              <span className="small-title font-bold theme-color pl-5">
                Individual Contribution
              </span>
              <div className="flex items-center justify-between right-id-sec">
                <Link to="" className="flex items-center justify-end">
                  <button className="2xl:px-10 lg:px-5 pt-3 py-2 btn-red-border text-xl border text-[#C00000]"
                    onClick={() => this.setState({ addNew: true, editDetails: false })}
                  >
                    <span className="text-xl text-[#c00000] font-semibold tracking-normal">
                      Add New
                    </span>
                  </button>
                </Link>
              </div>
            </label>

            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <IndividualContribution
                data={this.state.data}
                individualContributionList={this.state.individualContributionList}
                //saveContribution={this.saveContribution.bind(this)}
                contributionDetails={this.state.contributionDetails}
                roles={this.state.roles}
                editDetails={this.state.editDetails}
                addNew={this.state.addNew}

                parentList={this.state.parentList}
                individualBasicInformation={this.state.individualBasicInformation}

                //new
                saveContribution={this.addEditIndividualContribution.bind(this)}
                individualContribution={this.state.individualContribution}
                handleChangeCheckBoxContribution={this.handleChangeCheckBoxContribution.bind(this)}
                handleChange={this.handleChangeContribution.bind(this)}
                setFilterParameters={this.setFilterParametersContribution.bind(this)}
                individualProfile={this.state.individualProfile}
                validState={this.state.validStateIndContribution}
                validate={this.validateIndividualContribution.bind(this)}
                editContribution={this.editContribution.bind(this)}




              />
            </div>
          </div>
        </div>


        {/* SGTech Interest Card Start*/}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="sgtecgInterest"
              type="checkbox"
              name="corporate-d"
              value={"SGTechInterestCard"}
              checked={this.state.openSection === "SGTechInterestCard"}
              onClick={() => this.openSection("SGTechInterestCard")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="sgtecgInterest"
            >
              <span className="small-title font-bold theme-color pl-5">
                SGTech Interest
              </span>
            </label>

            <div className="tab-content overflow-hidden leading-normal px-5">
              <SGTechInterest
                _By={this.state._By}
                sgTech={this.state.reason}
                sponsorship={this.state.sponsorship}
                matching={this.state.matching}
                programs={this.state.programs}
                sIISCorporateInformation={this.state.sIISCorporateInformation}
                setFilterParameters={this.setFilterParameters.bind(this)}
                handleChange={this.handleChange.bind(this)}
                handleChangeCheckBox={this.handleChangeCheckBox.bind(this)}
                addEditSIISInformation={this.addEditSIISInformation.bind(this)}
                validState={this.state.validState}
                validate={this.corporateInformationValidate.bind()}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}

                //new
                sgtechInterest={this.state.sgtechInterest}
                addEditSGTechInterestProfile={this.addEditSGTechInterestProfile.bind(this)}
                handleChangeMultiDropdownInterest={this.handleChangeMultiDropdownInterest.bind(this)}
              />
            </div>
          </div>
        </div>

        {/* Communication Card Start*/}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="communicationCard"
              type="checkbox"
              name="corporate-d"
              value={"CommunicationCard"}
              checked={this.state.openSection === "CommunicationCard"}
              onClick={() => this.openSection("CommunicationCard")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="communicationCard"
            >
              <span className="small-title font-bold theme-color pl-5">
                Communication
              </span>
            </label>

            <div className="tab-content overflow-hidden leading-normal px-5">
              <CommunicationCard


                // new 
                sgtechCommunication={this.state.sgtechCommunication}
                addEditCommunicationProfile={this.addEditCommunicationProfile.bind(this)}
                handleChangeCheckBox={this.handleChangeCheckBox.bind(this)}
              />
            </div>
          </div>
        </div>

        {/* Financials Record Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="financialr"
              type="checkbox"
              name="corporate-d"
              value={"FinancialsRecord"}
              checked={this.state.openSection === "FinancialsRecord"}
              onClick={() => this.openSection("FinancialsRecord")}
            />

            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="financialr"
            >
              <span className="small-title font-bold theme-color pl-5">
                Financials
              </span>
            </label>

            <div className="tab-content overflow-hidden leading-normal px-5">
              <FinancialsRecord />
            </div>
          </div>
        </div>

        {/* Events Bookings Start */}

        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="eventb"
              type="checkbox"
              name="corporate-d"
              value={"EventsBookings"}
              checked={this.state.openSection === "EventsBookings"}
              onClick={() => this.openSection("EventsBookings")}
            />

            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="eventb"
            >
              <span className="small-title font-bold theme-color pl-5">
                Event Booking
              </span>
            </label>

            <div className="tab-content overflow-hidden leading-normal px-5">
              <EventsBookings />
            </div>
          </div>
        </div>
      </div>
    );
  }
}