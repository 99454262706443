import React, { Component } from "react";
import Selector from "../../components/dropdown/commonDropdown/Selector";
import ActionDeleteButton from "../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";
import SingleSelectWithSearch from "../../components/dropdown/SingleSelectWithSearch";
import SwalServices from "../../services/swalServices/SwalServices";
import EntryManagementServices from "../../services/axiosServices/apiServices/EntryManagementServices";
import { WebPath } from "../../utils/AppSetting";
import PageLoader from "../../components/common/pageLoader/PageLoader";
import { encryptAES } from "../../utils/Encryption";

export default class MarketingTrackingURL extends Component {
  constructor(props) {
    super(props);
    this.SwalServices = new SwalServices();
    this.entryManagementServices = new EntryManagementServices();
    this.state = {
      pagination: {
        //searcPartnerName: "",
        //searchEventName: "",
        partnerName: "",
        eventName: "",
      },
      totalResultes: "",
      getUrlList: [],
      partnerListSingleSelect: [],
      generateLink: {
        eventMarketingTrackingId: 0,
        partnerName: "",
        eventNameId: 0,
        generatedlink: "",
      },
      partnerName: [],
      partnerNameSingleSelect: [],
      //  agencyListTestSelected  agencyListTest  partnerListSelectedName
      //partnerListTest: [],
      partnerListSelectedName: "",
      partnerListTestSelected: 0,
      eventName: [],
      eventNameList: [],
      isLoading: false,
      validationRule: {
        partnerName: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "partner name"
            ),
          },
        ],
        eventNameId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "event name"
            ),
          },
        ],
      },

      validState: {
        isValid: true,
        error: {},
      },
    };
  }

  getAllMarketingTrackingURList = () => {
    this.setState({ isLoading: true });
    this.entryManagementServices
      .getAllMarketingTrackingURList(this.state.pagination)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent;
          this.setState({ getUrlList: details });
          this.setState({
            totalResultes: response.responseItem.responseContent.length,
          }, () => this.getAllMarketingTrackingURLEventList());
        } else {
          this.getAllMarketingTrackingURLEventList();
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  getAllMarketingTrackingURLPartnerList = () => {
    //this.setState({ isLoading: true });
    this.entryManagementServices
      .getAllMarketingTrackingURLPartnerList()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent;
          let temp = details.map((p) => ({
            id: p.eventMarketingTrackingId,
            name: p.partnerName,
            // value: p.eventMarketingTrackingId,
            // label: p.partnerName
          }));
          this.setState({ partnerListSingleSelect: temp }, () => {
            this.getAllMarketingTrackingURList();

          });
          this.setState({ partnerName: temp });
        } else {
          this.getAllMarketingTrackingURList();

          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  getAllMarketingTrackingURLEventList = () => {
    //this.setState({ isLoading: true });
    this.entryManagementServices
      .getAllMarketingTrackingURLEventList()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent;
          let temp = details.map((p) => ({
            //id: p.eventId, name: p.eventName
            value: p.eventId,
            label: p.eventName,
          }));
          this.setState({ eventName: temp, eventNameList: details });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  generateMarketTrackingURL = () => {
    let details = this.state.generateLink;
    details.partnerName = this.state.partnerListSelectedName;
    details.eventNameId = this.state.generateLink.eventNameId;
    this.setState({ generateLink: details });
    if (this.isValidateAllFields()) {
      let GetEventType = this.state.eventNameList.find(
        (item) => item.eventId === this.state.generateLink.eventNameId
      );
      let eventTypeName = "";
      if (GetEventType.eventTypeId === 1) {
        eventTypeName = "workshopDetails"; //Workshop Type
      } else if (GetEventType.eventTypeId === 2) {
        eventTypeName = "conferenceDetails"; // Conference Type
      } else {
        eventTypeName = "formDetails"; // Form Type
      }
      let encryptEventId = encryptAES(GetEventType.eventId);
      //let encryptEventId = encryptionData(GetEventType.eventId)
      let host = WebPath;
      let partnerN = this.state.generateLink.partnerName.replaceAll(" ", "-");
      let eventN = GetEventType.eventName.replaceAll(" ", "-");
      let generateUrl = `${host}programmes/${eventTypeName}/${encryptEventId}?p=${partnerN}&e=${eventN}`;
      let setData = this.state.generateLink;
      setData.generatedlink = generateUrl;
      this.setState({ generateLink: setData });
    }
  };

  resetField = () => {
    let reset = this.state.generateLink;
    reset.eventMarketingTrackingId = 0;
    reset.partnerName = "";
    reset.generatedlink = "";
    reset.eventNameId = 0;
    this.setState({
      generateLink: reset,
      partnerListTestSelected: 0,
      partnerListSelectedName: "",
    });
  };

  addEditMarketingTrackingURL = () => {
    if (this.isValidateAllFields()) {
      let request = {};
      request.eventMarketingTrackingId =
        this.state.generateLink.eventMarketingTrackingId;
      request.eventId = this.state.generateLink.eventNameId;
      request.partnerName = this.state.generateLink.partnerName;
      request.marketingTrackingURL = this.state.generateLink.generatedlink;
      this.entryManagementServices
        .addEditMarketingTrackingURL(request)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (
              response.responseItem.responseContent.errorColumn ===
              "MarketingTrackingURL"
            ) {
              // 'MarketingTrackingURL' AS ErrorColumn
              this.swalServices.Error("Marketing tracking URL already exists.");
            } else {
              this.getAllMarketingTrackingURList();
              this.SwalServices.Success(
                "Marketing tracking url saved successfully."
              );
              this.resetField();
              // this.resetFields();
              this.getAllMarketingTrackingURLPartnerList();
            }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        });
    }
  };

  deleteMarketingTrackingURL = (id) => {
    this.entryManagementServices
      .deleteMarketingTrackingURL(id)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.getAllMarketingTrackingURList();
          this.SwalServices.Success(
            "Marketing tracking url Deleted successfully."
          );
        } else {
          this.SwalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  getMarketingTrackingURLDetailById = (id) => {
    this.setState({ isLoading: true });
    this.entryManagementServices
      .getMarketingTrackingURLDetailById(id)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          //let newList = response.responseItem.responseContent;
          let data = this.state.generateLink;
          data.eventMarketingTrackingId =
            response.responseItem.responseContent.eventMarketingTrackingId;
          data.partnerName = response.responseItem.responseContent.partnerName;
          data.eventNameId = response.responseItem.responseContent.eventId;
          data.generatedlink =
            response.responseItem.responseContent.marketingTrackingURL;
          this.setState({
            generateLink: data,
            partnerListTestSelected:
              response.responseItem.responseContent.eventMarketingTrackingId,
            partnerListSelectedName:
              response.responseItem.responseContent.partnerName,
          });

          //let eventList = response.responseItem.responseContent;
          // agencyNameList = agencyNameList.map(p => ({
          //     value: p.agencyOfficerId, label: p.agentName
          // }));
          //this.setState({ agencyOfficerDropdown: agencyNameList });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  setFilterParametersTest = (id, name, drpIdentity) => {
    //var arr = [...this.state.ListSingleSelect]
    if(id>0){
      let arr = [...this.state.partnerListSingleSelect];
      let found = arr.filter((element) => element.name.includes(name));
      if (found?.name) {
        //this.getGovernmentAgencyById(id);
        this.getMarketingTrackingURLDetailById(id);
        const partnerListSelectedName = found.name;
        this.setState({
          partnerListTestSelected: id,
          // partnerName: found,
          partnerListSelectedName: partnerListSelectedName,
        });
      }
    }
  };

  resetFields = () => {
    let reset = this.state.generateLink;
    reset.eventMarketingTrackingId = 0;
    reset.partnerName = "";
    reset.eventNameId = 0;
    reset.generatedlink = "";
    this.setState({ generateLink: reset });
    //this.getMarketingTrackingURLListById(0);
  };

  handleChangeTest = (e) => {
    //this.setState({ partnerNameSingleSelect: this.state.partnerName })
    let arr = [...this.state.partnerListSingleSelect];
    let value = "";
    value = e.target.value;
    if (value === "" || value === null) {
      this.resetFields();
    }
    const found = arr.filter((element) =>
      element.name.toLowerCase().includes(value.toLowerCase())
    );
    // let addgeneratedLink = this.state.generateLink;
    // addgeneratedLink.generatedlink = value; generateLink: addgeneratedLink
    this.setState({ partnerListSelectedName: value, partnerName: found });
  };

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.generateLink,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.generateLink,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

 
  searchByName = (value, identity) => {
    let detail = this.state.pagination;
    detail.partnerName = "";
    detail.eventName = "";
    this.setState({ pagination: { ...detail } });
    if (identity === "searchByPartner") {
      if (value.length > 2) {
        detail.partnerName = value;
        this.setState({ pagination: detail });
        //this.getAllMarketingTrackingURList();
      }
    }
    if (identity === "searchByEvent") {
      if (value.length > 2) {
        detail.eventName = value;
        this.setState({ pagination: detail });
        //this.getAllMarketingTrackingURList();
      }
    }
    this.getAllMarketingTrackingURList();
  };

  componentDidMount() {
    this.getAllMarketingTrackingURLPartnerList();

  }

  //handle change in dropdown
  handleChangeInDropdown(selected, drpIdentity) {
    let partnerValidate = this.state.generateLink.partnerName;
    let eventNameValidate = this.state.generateLink.eventNameId;
    if (drpIdentity === "PartnerName") {
      let details = this.state.generateLink;
      details.partnerName = selected.value;
      this.setState({ generateLink: details });
      this.validateField("partnerName");
    } else if (drpIdentity === "EventName") {
      let detail = this.state.generateLink;
      detail.eventNameId = selected.value;
      this.setState({ generateLink: detail });
      this.validateField("eventNameId");
    }
    if (partnerValidate !== this.state.generateLink.partnerName) {
      let detail = this.state.generateLink;
      detail.generatedlink = "";
      this.setState({ generateLink: detail });
    } else if (eventNameValidate !== this.state.generateLink.eventNameId) {
      let detail = this.state.generateLink;
      detail.generatedlink = "";
      this.setState({ generateLink: detail });
    }
  }

  copyToClipboard = (copyUrl) => {
    // var copyText = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0, 99999);

    navigator.clipboard.writeText(copyUrl);
    this.SwalServices.Toaster("Link Copied");
    // alert("Copied the text: " + copyUrl);
  };

  render() {
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white">
            <div className="heading-part py-3 border-b">
              <div className="flex items-center justify-between pl-8">
                <h4 className="small-title font-bold theme-color ">
                  Create URL
                </h4>
              </div>
            </div>
            {/* Search by Partner and Events section */}

            <div className="filter-info grid grid-cols-12 gap-6 p-8">
              <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12 pr-12">
                <label
                  className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                  htmlFor="default"
                >
                  Search by Partner
                </label>
                <input
                  id="startDate"
                  name="startDate"
                  className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) =>
                    this.searchByName(e.target.value, "searchByPartner")
                  }
                />
              </div>
              <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12 pr-12">
                <label
                  className=" filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                  htmlFor="default"
                >
                  Search by Events
                </label>
                <input
                  id="endDate"
                  name="endDate"
                  className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) =>
                    this.searchByName(e.target.value, "searchByEvent")
                  }
                />
              </div>
              {/* <div className="xl:col-span-3 lg:col-span-3 col-span-12">
                                <div className="flex items-end justify-end h-full pb-1">
                                    <Link to="/CreatePreviewResource">
                                        <button className="btn btn-red text-white">
                                            <span className="text-xl text-white font-semibold tracking-normal">
                                                Search
                                            </span>
                                        </button>
                                    </Link>
                                </div>
                            </div> */}
            </div>
            {/* Search by Partner and Events section End */}
            {/* URL Grid */}
            {this.state.isLoading ? (
              <PageLoader />
            ) : (
              <div className="mb-2 overflow-x-auto mx-8 py-8">
                <table className="project-tbl w-full mx-auto my-5 fixed_header grid">
                  <thead>
                    <tr className="text-base font-bold flex">
                      <th className="px-2 first:pl-5 last:pr-5 tble-td-txt 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[13%]">
                        Partner Name
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tble-td-txt 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[20%]">
                        Event Name
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tble-td-txt 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[40%]">
                        URL
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tble-td-txt 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[10%]">
                        Visitor List
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tble-td-txt 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[7%]">
                        Booking
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tble-td-txt 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[10%]">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="scrollit text-sm custom-tbody marketing-tracking-td">
                    {this.state.getUrlList && this.state.getUrlList.length > 0 ? (
                      this.state.getUrlList.map((data, key) => {
                        return (
                          <tr className="custom-tbl-td flex" key={key}>
                            <td className=" px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[13%]">
                              {data.partnerName}
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 center-marketing-url-td whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                              {data.eventName}
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 center-marketing-url-td tble-td-txt text-[#757575] w-[40%]">
                              {data.marketingTrackingURL}
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[10%]">
                              {data.visitorList}
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[7%]">
                              {data.bookingCount}
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[10%] flex justify-around">
                              <ActionDeleteButton
                                onActionClick={() =>
                                  this.deleteMarketingTrackingURL(
                                    data.eventMarketingTrackingId
                                  )
                                }
                              />
                              <button
                                className="action-btn mr-4 flex items-center"
                                aria-controls="add-edit-modal"
                                onClick={() => {
                                  this.copyToClipboard(data.marketingTrackingURL);
                                }}
                              >
                                <img
                                  src="/copy-icon.png"
                                  className="w-[22px] mx-auto my-0"
                                  alt=""
                                ></img>
                              </button>
                              {/* <ActionDeleteButton
                                                                    onActionClick={() => this.copyToClipboard(data.marketingTrackingURL)}
                                                                /> */}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="grid justify-items-center">
                        <td className="text-lg text-[#757575]	">
                          No Records Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>)}
            {/* Generate Link Section */}
            <div className="filter-info grid grid-cols-12 gap-6 p-8">
              <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12 pr-12">
                <label
                  className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                  htmlFor="default"
                >
                  Partner Name
                </label>
                <SingleSelectWithSearch
                  drpIdentity={"PartnerName"}
                  optionArray={this.state.partnerName}
                  setFilterParameters={this.setFilterParametersTest.bind(this)}
                  value={this.state.partnerListTestSelected}
                  handleChangeTest={this.handleChangeTest.bind(this)}
                  searchText={this.state.partnerListSelectedName}
                />
                <div>
                  <ValidationText
                    error={this.state.validState.error.partnerName}
                  />
                </div>
              </div>
              <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12 pr-12">
                <label
                  className=" filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                  htmlFor="default"
                >
                  Event Name
                </label>
                <Selector
                  options={this.state.eventName}
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{
                  //   Option,
                  // }}
                  onChange={this.handleChangeInDropdown.bind(this)}
                  allowSelectAll={false}
                  value={this.state.eventName.find(
                    (item) => item.value === this.state.generateLink.eventNameId
                  )}
                  drpIdentity="EventName"
                />
                <div>
                  <ValidationText
                    error={this.state.validState.error.eventNameId}
                  />
                </div>
              </div>
              <div className="xl:col-span-2 lg:col-span-2 col-span-12">
                <div className="flex items-end justify-end h-full pb-1">
                  {/* <Link to="/">
                                        <button
                                            className="btn bg-[#56A351] text-white"
                                            onClick={() => this.generateMarketTrackingURL()}
                                        >
                                            <span className="text-xl text-white font-semibold tracking-normal">
                                                Generate URL
                                            </span>
                                        </button>
                                    </Link> */}
                  <button
                    className="btn bg-[#56A351] text-white"
                    onClick={() => this.generateMarketTrackingURL()}
                  >
                    <span className="text-xl text-white font-semibold tracking-normal whitespace-nowrap">
                      Generate URL
                    </span>
                  </button>
                </div>
              </div>
            </div>
            {/* Generate Link Section End */}
            {/* Save URL Link */}
            <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-2 sm:grid-cols-1 gap-0 px-8 py-8">
              <div className="xl:col-span-8 post-details ck-content disabled-input">
                <h2 className="text-xl theme-color mb-1">URL Link</h2>
                <textarea
                  className="w-full h-[120px]"
                  placeholder=""
                  // id="meta_description"
                  id="myInput"
                  name="generatedlink"
                  rows="4"
                  cols="4"
                  disabled={true}
                  value={this.state.generateLink.generatedlink}
                // value={this.state.notificationDetails.message}
                //onChange={(event) => this.handleChange(event)}
                // onBlur={() => this.validateField("message")}
                />
                {/* {this.state.generateLink && this.state.generateLink.generatedlink === "" ?
                                    null
                                    :
                                    (<button
                                        className="btn bg-[#C00000] text-white"
                                        onClick={() => this.copyToClipboard()}
                                    >
                                        <span className="text-xl text-white font-semibold tracking-normal">
                                            {` Copy URL `}
                                        </span>
                                    </button>)
                                } */}
              </div>
              <div className="xl:col-span-4 pt-24 pr-8">
                <div className="flex items-center justify-end">
                  {/* {this.state.notificationDetails.notificationAnnouncementId === 0 ? */}
                  <button
                    className={`btn text-white ${this.state.generateLink.generatedlink &&
                      this.state.generateLink.generatedlink !== ""
                      ? "btn-red"
                      : "bg-[#e3e3e3]"
                      }`}
                    onClick={() => this.addEditMarketingTrackingURL()}
                    disabled={
                      !(this.state.generateLink.generatedlink &&
                        this.state.generateLink?.generatedlink !== "")
                    }
                  >
                    <span className="text-xl text-[#757575] font-semibold tracking-normal">
                      {` Save `}
                    </span>
                  </button>
                  {/* {this.state.generateLink.generatedlink === null || this.state.generateLink.generatedlink === '' ?
                                        //<>disable</>
                                        <button
                                            className="btn bg-[#e3e3e3] text-white"
                                            //onClick={() => this.addEditMarketingTrackingURL()}
                                            disabled={true}
                                        >
                                            <span className="text-xl text-white font-semibold tracking-normal">
                                                {` Save `}
                                            </span>
                                        </button>
                                        :
                                        //<>Enable</>
                                        <button
                                            className="btn btn-red text-white"
                                            onClick={() => this.addEditMarketingTrackingURL()}
                                            disabled={false}
                                        >
                                            <span className="text-xl text-white font-semibold tracking-normal">
                                                {` Save `}
                                            </span>
                                        </button>
                                    } */}
                  {/* <button
                                        className="btn btn-red text-white"
                                        onClick={() => this.addEditMarketingTrackingURL()}
                                        disabled={true}
                                    >
                                        <span className="text-xl text-white font-semibold tracking-normal">
                                            {` Save `}
                                        </span>
                                    </button> */}

                  {/* } */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
