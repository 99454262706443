import React from "react";
import MultiSelectDropdown from "../../../dropdown/MultiSelectDropdown";
import Option from "../../../dropdown/multiSelectDropdownOption/MultiSelectOption";
import Validation from "../../../../utils/validation/ValidationText";
import ImageCropperWithUpload from "../../../ImageUpload/UploadImage";
import DropdownSelect from "../../../dropdown/Dropdown";
import TextInput from "../../../UIComponent/TextInput";
import DatePicker from "../../../datePicker/DatePicker";
import moment from "moment";
import Selector from "../../../dropdown/commonDropdown/Selector"
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader";

function OrganisationProfile(props) {
  return (
    <div>
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="grid grid-cols-12 gap-6 w-full ">
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6 w-full ">
                  <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12">
                        <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                          Countries with Presence{" "}
                          <span className="text-[#c00000]">*</span>
                        </h2>

                        <div className="dropdwn-input flex items-center">
                          <div className="w-full">
                            <MultiSelectDropdown
                              drpIdentity="countriesWithPresence"
                              options={props.countriesWithPresence}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{ Option }}
                              onChange={props.handleChangeMultiDropdown}
                              allowSelectAll={true}
                              value={
                                props.organizationProfile.selectedCountriesArr
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Validation
                      error={props.validState.error.selectedCountriesArr}
                    />
                  </div>
                  <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12">
                        <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                          Product Software{" "}
                          {/* <span className="text-[#c00000]">*</span> */}
                        </h2>
                        <div className="w-full outline-none appearance-none">
                          <MultiSelectDropdown
                            drpIdentity="coSoftwares"
                            options={props.coProductSoftware}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ Option }}
                            onChange={props.handleChangeMultiDropdown}
                            allowSelectAll={true}
                            value={
                              props.organizationProfile.selectedSoftwaresArr
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <Validation
                      error={props.validState.error.selectedSoftwaresArr}
                    />
                  </div>

                  <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12">
                        <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                          Business Sector{" "}
                          <span className="text-[#c00000]">*</span>
                        </h2>
                        <div className="w-full outline-none appearance-none">
                          <MultiSelectDropdown
                            drpIdentity="businessSectors"
                            options={props.businessSector}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ Option }}
                            onChange={props.handleChangeMultiDropdown}
                            allowSelectAll={true}
                            value={props.organizationProfile.selectedSectorsArr}
                          />
                        </div>
                      </div>
                    </div>
                    <Validation
                      error={props.validState.error.selectedSectorsArr}
                    />
                  </div>
                  <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12">
                        <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                          Product Services
                          {/* <span className="text-[#c00000]">*</span> */}
                        </h2>
                        <div className="w-full outline-none appearance-none">
                          <MultiSelectDropdown
                            drpIdentity="coProductServices"
                            options={props.coProductService}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ Option }}
                            onChange={props.handleChangeMultiDropdown}
                            allowSelectAll={true}
                            value={
                              props.organizationProfile.selectedServicesArr
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <Validation
                      error={props.validState.error.selectedServicesArr}
                    />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6 w-full ">
                  <div className="2xl:col-span-12 lg:col-span-12 col-span-12 ml-auto">
                    <div className="company-logo-cropper ml-auto mr-auto">
                      <div className="individual-profile-create-cropper">
                        <ImageCropperWithUpload
                          key="ProfilePic"
                          keyId="ProfilePic"
                          height={304}
                          width={643}
                          isCheckValidation={true}
                          MaxFileSize={2097152}
                          MaxFileSizeMessage={"2MB"}
                          onSaveImage={props.saveMainImage}
                          imageIdentity="CorporateMembers"
                          intitImage={props.organizationProfile.logoImage}
                          aspactRatio={643 / 304}
                          uploadBtn={"Upload Company Logo"}

                        />

                      </div>
                      <div className=" text-center">
                        <Validation
                          error={props.validState.error.logoImage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="grid grid-cols-12 gap-6 w-full ">
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Product Hardware
                      {/* <span className="text-[#c00000]">*</span> */}
                    </h2>
                    <div className="flex items-center">
                      <div className="w-full outline-none appearance-none">
                        <MultiSelectDropdown
                          drpIdentity="coHardwares"
                          options={props.coProductHardware}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{ Option }}
                          onChange={props.handleChangeMultiDropdown}
                          allowSelectAll={true}
                          value={props.organizationProfile.selectedHardwaresArr}
                        />
                      </div>

                      <Validation
                        error={props.validState.error.selectedHardwaresArr}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Area of Expertise
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <div className="w-full outline-none appearance-none">
                        <MultiSelectDropdown
                          drpIdentity="areaOfExp"
                          options={props.areaOfExpertise}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{ Option }}
                          onChange={props.handleChangeMultiDropdown}
                          allowSelectAll={true}
                          value={props.organizationProfile.selectedExpertiseArr}
                        />
                      </div>
                    </div>
                    <Validation
                      error={props.validState.error.selectedExpertiseArr}
                    />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Org Gross Turnover{" "}
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <DropdownSelect
                        drpIdentity={"grossTurnOver"}
                        optionArray={props.corporateGrossTurnovers}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationProfile.orgGrossTurnoverId}
                      // onBlur={(e) =>
                      //   props.validate(
                      //     "corporateMemberRecordTypeId",
                      //     props.corporateSIISAccount
                      //   )
                      // }
                      />
                    </div>
                    <Validation
                      error={props.validState.error.orgGrossTurnoverId}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-12 gap-6 mt-9">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Website
                      {/* <span className="text-[#c00000]">*</span> */}
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="website"
                        value={props.organizationProfile.website}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                        onBlur={(e) =>
                          props.validate("website", props.organizationProfile)
                        }
                      />

                      <Validation error={props.validState.error.website} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Company Description
                      {/* <span className="text-[#c00000]">*</span> */}
                    </h2>
                    <div className="flex items-center">
                      <textarea
                        className="w-full outline-none appearance-none form-input rounded-none h-[150px]"
                        type="text"
                        name="companyDescription"
                        value={props.organizationProfile.companyDescription}
                        identity="OrganizationProfile"
                        onChange={(event) => props.handleChange(event, "OrganizationProfile")}

                      />
                    </div>
                    <Validation
                      error={props.validState.error.companyDescription}
                    />
                    {(props &&
                      props.charLimit -
                      props.countCompanyDescriptionForSIIS) <= 0 ? (
                      <div className="text-white bg-[#717171] w-[300px] rounded text-center mb-1">
                        You reach the character enter limit
                      </div>
                    ) : (
                      <div
                        className={`mb-1 rounded text-[#fff] w-[80px] text-center ${props.charLimit -
                          props.countCompanyDescriptionForSIIS <
                          50
                          ? "bg-[#C00000]"
                          : props.charLimit -
                            props.countCompanyDescriptionForSIIS <
                            100
                            ? "bg-[#ffc107]"
                            : "bg-blue-500"
                          }`}
                      >
                        {props.charLimit -
                          props.countCompanyDescriptionForSIIS}
                        /{props.charLimit}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Business Contact Number
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="email"
                        name="businessContactNumber"
                        value={props.organizationProfile.businessContactNumber}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                        onBlur={(e) =>
                          props.validate(
                            "businessContactNumber",
                            props.organizationProfile
                          )
                        }
                      />
                    </div>
                    <Validation
                      error={props.validState.error.businessContactNumber}
                    />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Business Email
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="email"
                        name="businessEmail"
                        value={props.organizationProfile.businessEmail}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                        onBlur={(e) =>
                          props.validate(
                            "businessEmail",
                            props.organizationProfile
                          )
                        }
                      />
                    </div>
                    <Validation error={props.validState.error.businessEmail} />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Mailing Address Line 1<span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="mailingAddress"
                        value={props.organizationProfile.mailingAddress}
                        maxLength={40}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                        onBlur={(e) =>
                          props.validate(
                            "mailingAddress",
                            props.organizationProfile
                          )
                        }
                      />
                    </div>
                    <Validation error={props.validState.error.mailingAddress} />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Mailing Address Line 2
                      {/* <span className="text-[#c00000]">*</span> */}
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="mailingAddress2"
                        value={props.organizationProfile.mailingAddress2}
                        maxLength={40}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                      // onBlur={(e) =>
                      //   props.validate(
                      //     "mailingAddress2",
                      //     props.organizationProfile
                      //   )
                      // }
                      />
                    </div>
                    <Validation error={props.validState.error.mailingAddress2} />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Mailing Address  line 3
                      {/* <span className="text-[#c00000]">*</span> */}
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="mailingAddress3"
                        value={props.organizationProfile.mailingAddress3}
                        maxLength={40}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                      // onBlur={(e) =>
                      //   props.validate(
                      //     "mailingAddress3",
                      //     props.organizationProfile
                      //   )
                      // }
                      />
                    </div>
                    <Validation error={props.validState.error.mailingAddress3} />
                  </div>
                </div>
              </div>
              {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-3">
                      Mailing Address 2
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="add2"
                        // value={
                        //   props.organizationProfile.areaofExpertise
                        // }
                        // identity="OrganizationProfile"
                        // handleChange={props.handleChange}
                        // onBlur={(e) =>
                        //   props.validate(
                        //     "areaofExpertise",
                        //     props.organizationProfile
                        //   )
                        // }

                        // value={this.state.searchbyParent}
                        // handleChange={(event) => {
                        //   this.setState({ searchByName: event.target.value });
                        // }}
                      />

                      <Validation
                        error={props.validState.error.areaofExpertise}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Country
                      <span className="text-[#c00000]">*</span>
                    </h2>

                    <Selector
                      drpIdentity="orgCountry"
                      options={props.countriesWithPresence}
                      // isMulti
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      // components={{ Option }}
                      onChange={props.handleChangeMultiDropdown}
                      allowSelectAll={false}
                      value={props.countriesWithPresence.find(country => country.value === props.organizationProfile.countryId)}
                    />
                    {/* <DropdownSelect
                      drpIdentity={"orgCountry"}
                      optionArray={props.countriesSingleDrp}
                      setFilterParameters={props.setFilterParameters}
                      value={props.organizationProfile.countryId}

                    /> */}
                    <Validation error={props.validState.error.countryId} />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      City
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="city"
                        value={props.organizationProfile.city}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                        onBlur={(e) =>
                          props.validate("city", props.organizationProfile)
                        }
                      />
                    </div>
                    <Validation error={props.validState.error.city} />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Postal Code
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="postalCode"
                        maxLength={20}
                        value={props.organizationProfile.postalCode}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                        onBlur={(e) =>
                          props.validate(
                            "postalCode",
                            props.organizationProfile
                          )
                        }
                        autoComplete={"off"}

                      />
                    </div>
                    <Validation error={props.validState.error.postalCode} />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      LinkedIn
                      {/* <span className="text-[#c00000]">*</span> */}
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="linkedIn"
                        value={props.organizationProfile.linkedIn}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                        onBlur={(e) =>
                          props.validate("linkedIn", props.organizationProfile)
                        }
                      />

                      <Validation error={props.validState.error.linkedIn} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Updated By
                      {/* <span className="text-[#c00000]">*</span> */}
                    </h2>
                    <div className="disabled-input flex items-center">
                      <DropdownSelect
                        disabled={true}
                        drpIdentity={"updatedById"}
                        optionArray={props._By}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationProfile.updatedBy}
                      // onBlur={(e) => props.validate("updatedById", props.corporateSIISAccount)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Last Updated Date
                      {/* <span className="text-[#c00000]">*</span> */}
                    </h2>
                    <div className="disabled-input">
                      {props.organizationProfile.updatedAt === "" || props.organizationProfile.updatedAt === null || props.organizationProfile.updatedAt === "1900-01-01T00:00:00" ?

                        <TextInput
                          disabled={true}
                          placeholder=""
                          type="text"
                          name="linkedIn"
                          value="None"
                          identity="BasicInformation"
                        />
                        :
                        <DatePicker
                          isDisable={true}
                          name="updatedAt"
                          handleChange={props.handleChange}
                          identity="updatedAt"
                          value=
                          {moment(props.organizationProfile.updatedAt).format("yyyy-MM-DD")}
                        />
                      }
                    </div>
                    {/* <div className="disabled-input flex items-center">
                      <DatePicker
                        name="updatedAt"
                        isDisable={true}
                        handleChange={props.handleChange}
                        identity="OrganizationProfile"
                        value={moment(
                          props.organizationProfile.updatedAt
                        ).format("yyyy-MM-DD")}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Save Button */}
      <div className="grid grid-cols-12 gap-6 pb-10">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
          <div
            className="flex items-center justify-end"
          >
            {
              props && props.isLoading === true ?
                (
                  <ButtonLoader />
                )
                :
                (
                  <button
                    className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                    onClick={() => props.onSave()}
                  >
                    Save
                  </button>
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganisationProfile;
