import React from "react";

const SectionWithBorder = (props) => {
    return (
        <>
            <div className="border-solid border border-[#757575] w-full mb-8">
                <div className=" grid grid-cols-grid-3 border-b border-[#757575]">
                    <div className="text-xl text-left py-4 pl-4 text-[#757575] font-bold">{props.title}</div>
                </div>
                {props.children}
            </div>
        </>
    );
}
export default SectionWithBorder;