import React from "react";
import AddEditProfile from "./AddEditProfile";

function EmployeesList(props) {
  return (
    <div>
      <div className="pb-5 pt-5">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="shadow-md">
              {props.employeeList.map((emp, key) => (
                <>
                  <div className="tab w-full overflow-hidden border-t" key={key}>
                    <input
                      className="absolute opacity-0"
                      id={key}
                      type="radio"
                      name="tabs2"
                    />
                    <label
                      className="flex items-center justify-between relative py-5 px-12 w-auto leading-normal cursor-pointer"
                      htmlFor={key}
                    >
                      <span className="text-[#c00000] text-xl font-bold w-[40%]">
                        {emp.firstName} {emp.lastName}
                      </span>
                      <span className="text-[#757575] text-xl w-[40%]">
                        {emp.designation}
                      </span>
                      <span className="w-[10%]">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            props.setAddEditModel(true);
                            props.onEditEmployee(emp.individualMemberId)
                            props.setLoader(false);
                            props.handleisAddEditAOrganisationEmployees(false);
                          }}
                          className="underline text-xl text-[#8DA4B5]"
                        >
                          Edit Profile
                        </button>
                      </span>
                    </label>
                    <div className="tab-content overflow-hidden leading-normal px-12">
                      <h2 className="text-xl text-[#757575] 2xl:mb-1 flex items-center">
                        <span className="w-[15%]">Email:</span>{" "}
                        <span className="ml-5">{emp.email}</span>
                      </h2>
                      <h2 className="text-xl text-[#757575] 2xl:mb-1 lg:mb-5 flex items-center">
                        <span className="w-[15%]">Contact Number:</span>{" "}
                        <span className="ml-5">{emp.contactNo}</span>
                      </h2>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="add-edite-employee-popup">
        <AddEditProfile
          mrMrs={props.mrMrs}
          emailDomain={props.emailDomain}
          employeeDetails={props.employeeDetails}
          handleChange={props.handleChange}
          setFilterParameters={props.setFilterParameters}
          saveEmployee={props.saveEmployee}
          // addEditModel={addEditModel}
          setOpenModal={props.setAddEditModel}
          validate={props.validate}
          validState={props.validState}
          domainArr={props.domainArr}
          onClose={(e) => {
            e.stopPropagation();
            props.setAddEditModel(false);
          }}
          handleChangeMultiDropdown={props.handleChangeMultiDropdown}
          designationList={props.designationList}
          isLoaderOn={props.isLoaderOn}
          setLoader={props.setLoader}
          addEditModel={props.addEditModelForEmployee}
          setAddEditModel={props.setAddEditModel}
          markIndivisualMemberAsNonMember={props.markIndivisualMemberAsNonMember}
          isCreateNewEmployee={props.isCreateNewEmployee}
          //isEditEmployee={props.isEditEmployee}
          isEmployeeModalEditable={props.isEmployeeModalEditable}
          searchIndividualMemberByEmailForOrgRole={props.searchIndividualMemberByEmailForOrgRole}
          handleisAddEditAOrganisationEmployees={props.handleisAddEditAOrganisationEmployees}
          isCreateNewOrgRoleForEmployee={props.isCreateNewOrgRoleForEmployee}
          isAddEditProfileButtonLoader={props.isAddEditProfileButtonLoader}
        />
      </div>
    </div>
  );
}

export default EmployeesList;
