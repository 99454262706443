import React, { useState } from "react";
import DatePicker from "../../datePicker/DatePicker";
import DropdownSelect from "../../dropdown/Dropdown";
import TextInput from "../../UIComponent/TextInput";
import moment from "moment";
import Validation from "../../../utils/validation/ValidationText";

function IndividualContribution(props) {
  const [editDetail, setEditDetails] = useState(0);

  const editContribution = (id, data) => {
    setEditDetails(id);
    props.editContribution(id, data);
  };



  return (
    <div className="w-full">
      <div className="grid grid-cols-12 gap-6 w-full items-center">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12">
          <div className="grid grid-cols-12 gap-6">
            <div className="xl:col-span-12 lg:col-span-12 col-span-12 ">
              <div className="">
                <div className="maping-table">
                  {props.addNew ? (
                    <>
                      <div className="w-full px-8">
                        {/* xcbvhbxhj */}
                        {/* <span className="px-5 contributor-titlev font-bold tble-th-txt text-[#757575]">
                          {key + 1}. {contribution.contributionSection}
                        </span> */}
                        <div className="grid grid-cols-12 gap-6 mt-5">
                          <div className="xl:col-span-4 lg:col-span-4 col-span-12 text-left">
                            <div className="text-[#757575] text-xl">
                              <label className="text-[#757575] xl:text-xl font-bold pb-4">
                                {" "}
                                Chapter/Committee Name
                              </label>
                            </div>
                            <div className="text-[#757575] text-xl min-w-[100%]">
                              {/* <TextInput
                                  identity="IndividualContribution"
                                  className="pt-4 "
                                  placeholder=""
                                  type="text"
                                  name="contributionSection"
                                  value={props.contributionDetails.contributionSection}
                                  handleChange={props.handleChange}
                                  onBlur={(e) => props.validate("contributionSection", props.contributionDetails)}
                                /> */}
                              {/* <DropdownSelect
                                  drpIdentity={"IndividualContributionRole"}
                                  optionArray={props.parentList}
                                  setFilterParameters={props.setFilterParameters}
                                  value={props.contributionDetails.workgroupMappingId}
                                  validateField={() => props.validateField("workgroupMappingId", props.contributionDetails)}
                                /> */}
                              <DropdownSelect
                                drpIdentity={"Chapter/Commitee"}
                                optionArray={props.parentList}
                                setFilterParameters={props.setFilterParameters}
                                value={
                                  props.individualContribution
                                    .workgroupMappingId
                                }
                              />
                            </div>
                            <Validation
                              error={props.validState.error.workgroupMappingId}
                            />
                          </div>

                          <div className="xl:col-span-4 lg:col-span-4 col-span-12">
                            <div className="flex items-center register-desc pt-11">
                              <input
                                id="chapterInvolvement"
                                name="chapterInvolvement"
                                type="checkbox"
                                className="cursor-pointer h-6 w-6 text-[#C00000] focus:ring-[#C00000] check-box-custom"
                                onChange={(event) =>
                                  props.handleChangeCheckBoxContribution(event)
                                }
                                checked={
                                  props.individualContribution
                                    .chapterInvolvement
                                }
                                value={
                                  props.individualContribution
                                    .chapterInvolvement
                                }
                              />
                              <label className="communication-label contribution-title ml-3 block text-[#757575] font-bold 2xl:text-[22px]">
                                SGTech Chapter Involvement
                                <span className="text-[#c00000]">*</span>
                              </label>
                            </div>
                          </div>

                          <div className="xl:col-span-4 lg:col-span-4 col-span-12">
                            <div className="flex items-center register-desc pt-11">
                              <input
                                id="committeeInvolvement"
                                name="committeeInvolvement"
                                type="checkbox"
                                className="cursor-pointer h-6 w-6 text-[#C00000] focus:ring-[#C00000] check-box-custom"
                                onChange={(event) =>
                                  props.handleChangeCheckBoxContribution(event)
                                }
                                checked={
                                  props.individualContribution
                                    .committeeInvolvement
                                }
                                value={
                                  props.individualContribution
                                    .committeeInvolvement
                                }
                              />
                              <label className="communication-label contribution-title ml-3 block text-[#757575] font-bold 2xl:text-[22px]">
                                Committee Involvement
                                <span className="text-[#c00000]">*</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <table className="w-full bg-white rounded-sm contribution-tble">
                        {/* Table header */}

                        <thead className="text-xs text-[#757575]">
                          <tr className="max-w-full">
                            <th className="first:pl-8 last:pr-5 w-[34%] text-left pb-0 tbl-th text-[#757575]">
                              <div className="font-bold tble-th-txt">Name</div>
                            </th>
                            <th className="first:pl-8 last:pr-5 w-[20%] text-left pb-0 tbl-th text-[#757575]">
                              <div className="font-bold tble-th-txt">
                                {/* Role */}Organisation Key Role
                              </div>
                            </th>
                            <th className="first:pl-8 last:pr-5 w-[18%] text-left pb-0 tbl-th text-[#757575]">
                              <div className="font-bold  tble-th-txt">
                                Start Date
                              </div>
                            </th>
                            <th className="first:pl-8 last:pr-5 w-[18%] text-left tbl-th pb-0 text-[#757575]">
                              <div className="font-bold tble-th-txt">
                                End Date
                              </div>
                            </th>
                            <th className="first:pl-8 last:pr-5 w-[10%] text-left  tbl-th pb-0 text-[#757575]">
                              <div className="font-bold tble-th-txt"></div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm custom-tbody">
                          <tr className="custom-tbl-td-contribution  border-b border-[#c9c9c9]">
                            <td className="first:pl-8 pr-5 text-lg pb-9  w-[34%] text-left">
                              <div className="text-[#757575] text-xl min-w-[100%]">
                                <div className="disabled-input">
                                  <TextInput
                                    className="pt-4 "
                                    identity="IndividualContribution"
                                    placeholder=""
                                    type="text"
                                    name="name"
                                    // value={props.contributionDetails.name}
                                    value={
                                      props.individualProfile.firstName +
                                      " " +
                                      props.individualProfile.lastName
                                    }
                                    handleChange={props.handleChange}
                                    // onBlur={(e) => props.validate("name", props.contributionDetails)}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </td>
                            <td className="first:pl-8 pr-5 text-lg pb-9  w-[20%] text-left overflow-unser-part">
                              <div className="text-[#757575] text-xl min-w-[100%]">
                                <DropdownSelect
                                  drpIdentity={"Role"}
                                  optionArray={props.roles}
                                  setFilterParameters={
                                    props.setFilterParameters
                                  }
                                  value={props.individualContribution.roleId}
                                  onBlur={(e) =>
                                    props.validate(
                                      "roleId",
                                      props.individualContribution
                                    )
                                  }
                                />
                                <Validation
                                  error={props.validState.error.roleId}
                                />
                              </div>
                            </td>
                            <td className="first:pl-8 pr-5 text-lg pb-9 w-[18%] text-center">
                              <div className="text-[#757575] text-xl min-w-[100%]">
                                <DatePicker
                                  name="startDate"
                                  identity="IndividualContribution"
                                  value={props.individualContribution.startDate}
                                  handleChange={props.handleChange}
                                  onBlur={(e) =>
                                    props.validate(
                                      "startDate",
                                      props.individualContribution
                                    )
                                  }
                                />
                                <Validation
                                  error={props.validState.error.startDate}
                                />
                              </div>
                            </td>
                            <td className="first:pl-8 pr-5 text-lg pb-9 w-[18%] text-center">
                              <div className="text-[#757575] text-xl min-w-[100%]">
                                <DatePicker
                                  identity="IndividualContribution"
                                  name="endDate"
                                  value={props.individualContribution.endDate}
                                  handleChange={props.handleChange}
                                />
                              </div>
                              {/* <Validation error={props.validState.error.endDate} /> */}
                            </td>
                            <td className="first:pl-8 last:pr-5 text-lg w-[10%] text-center  pb-9 px-2 tble-td-txt">
                              <div className="action-part text-xl">
                                <a
                                  className="text-[#c00000] underline"
                                  onClick={() => props.saveContribution(0)}
                                >
                                  Save
                                </a>
                              </div>
                            </td>
                          </tr>

                          {/* vfkjbhd */}
                        </tbody>
                        {/* Table body */}
                      </table>
                    </>
                  ) : null}

                  {/* Right  df */}
                  {props.individualContributionList.map((contribution, key) => {
                    return (
                      <div className="pt-8 contents" key={key}>
                        <span className="px-5 contributor-titlev font-bold tble-th-txt text-[#757575]">
                          {key + 1}. {contribution.contributionName}
                        </span>
                        <div className="w-full px-8">
                          <div className="text-left">
                            {editDetail ===
                              contribution.individualContributionId ? (
                              <div className="grid grid-cols-12 gap-6 mt-5">
                                <div className="xl:col-span-4 lg:col-span-4 col-span-12 text-left">
                                  <div className="text-[#757575] text-xl">
                                    <label className="text-[#757575] font-bold xl:text-xl pb-4">
                                      Chapter/Committee Name
                                      <span className="text-[#C00000]">
                                        *
                                      </span>
                                    </label>
                                    <DropdownSelect
                                      drpIdentity={"Chapter/Commitee"}
                                      optionArray={props.parentList}
                                      setFilterParameters={props.setFilterParameters}
                                      value={props.individualContribution.workgroupMappingId}
                                    />
                                    <Validation
                                      error={props.validState.error.workgroupMappingId}
                                    />
                                  </div>
                                </div>

                                <div className="xl:col-span-4 lg:col-span-4 col-span-12">
                                  <div className="flex items-center register-desc pt-11">
                                    <input
                                      id="chapterInvolvement"
                                      name="chapterInvolvement"
                                      type="checkbox"
                                      className="cursor-pointer h-6 w-6 text-[#C00000] focus:ring-[#C00000] check-box-custom"
                                      onChange={(event) =>
                                        props.handleChangeCheckBoxContribution(
                                          event
                                        )
                                      }
                                      checked={props.individualContribution.chapterInvolvement}
                                      value={
                                        props.individualContribution
                                          .chapterInvolvement
                                      }
                                    />
                                    <label className="communication-label contribution-title ml-3 block text-[#757575] font-bold 2xl:text-[22px]">
                                      SGTech Chapter Involvement
                                      <span className="text-[#c00000]">
                                        *
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="xl:col-span-4 lg:col-span-4 col-span-12">
                                  <div className="flex items-center register-desc pt-11">
                                    <input
                                      id="committeeInvolvement"
                                      name="committeeInvolvement"
                                      type="checkbox"
                                      className="cursor-pointer h-6 w-6 text-[#C00000] focus:ring-[#C00000] check-box-custom"
                                      onChange={(event) =>
                                        props.handleChangeCheckBoxContribution(
                                          event
                                        )
                                      }
                                      checked={
                                        props.individualContribution
                                          .committeeInvolvement
                                      }
                                      value={
                                        props.individualContribution
                                          .committeeInvolvement
                                      }
                                    />
                                    <label className="communication-label contribution-title ml-3 block text-[#757575] font-bold 2xl:text-[22px]">
                                      Committee Involvement
                                      <span className="text-[#c00000]">
                                        *
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <table className="w-full bg-white rounded-sm contribution-tble">
                          {/* Table header */}
                          <thead className="text-xs font-semibold capitalize text-[#757575]">
                            <tr>
                              <th className="first:pl-8 last:pr-5 w-[34%] text-left tbl-th text-[#757575]">
                                <div className="font-bold tble-th-txt">
                                  Name
                                </div>
                              </th>
                              <th className="first:pl-8 last:pr-5 w-[20%] text-left tbl-th text-[#757575]">
                                <div className="font-bold tble-th-txt">
                                  Role
                                </div>
                              </th>
                              <th className="first:pl-8 last:pr-5 w-[18%] text-left tbl-th text-[#757575]">
                                <div className="font-bold  tble-th-txt">
                                  Start Date
                                </div>
                              </th>
                              <th className="first:pl-8 last:pr-5 w-[18%] text-left tbl-th text-[#757575]">
                                <div className="font-bold tble-th-txt">
                                  End Date
                                </div>
                              </th>
                              <th className="first:pl-8 last:pr-5 w-[10%] text-center  tbl-th px-2 text-[#757575]">
                                <div className="font-bold tble-th-txt"></div>
                              </th>
                            </tr>
                          </thead>
                          {/* Table body */}
                          <tbody className="text-sm custom-tbody">
                            {/* <tr className="custom-tbl-td-contribution border-b border-[#c9c9c9]">
                                  <td className="first:pl-8 last:pr-5 text-lg pb-9 px-2 whitespace-nowrap tble-td-txt">
                                    <div className="text-[#757575] text-xl min-w-[100%]">
                                      {contribution.name}
                                    </div>
                                  </td>
                                  <td className="first:pl-8 last:pr-5 text-lg pb-9 px-2 whitespace-nowrap tble-td-txt">
                                    <div className="text-[#757575] text-xl min-w-[100%]">
                                      {contribution.roleName}
                                    </div>
                                  </td>
                                  <td className="first:pl-8 last:pr-5 text-lg pb-9 px-2 whitespace-nowrap tble-td-txt">
                                    <div className="text-[#757575] text-xl min-w-[100%]">
                                
                                      {moment(contribution.startDate).format(
                                        "DD MMM YYYY"
                                      )}
                                    </div>
                                  </td>
                                  <td className="first:pl-8 last:pr-5 text-lg pb-9 px-2 whitespace-nowrap tble-td-txt">
                                    <div className="text-[#757575] text-xl min-w-[100%]">
                                      {moment(contribution.endDate).format(
                                        "DD MMM YYYY"
                                      )}
                                    </div>
                                  </td>
                                  <td className="first:pl-8 last:pr-5 text-lg pb-9 px-2 whitespace-nowrap tble-td-txt">
                                    <div className="action-part text-xl">


                                      <a
                                        className="text-[#7B97AA] underline"
                                        onClick={() => props.editContribution(contribution.individualContributionId, contribution)}
                                      >
                                        Edit
                                      </a>
                                    </div>
                                  </td>
                                </tr> */}
                            {editDetail ===
                              contribution.individualContributionId ? (
                              <tr className="custom-tbl-td-contribution  border-b border-[#c9c9c9]">
                                <td className="first:pl-8 pr-5 text-lg pb-9 w-[34%] text-left">
                                  <div className="text-[#757575] text-xl min-w-[100%]">
                                    <div className="disabled-input">
                                      <TextInput
                                        className="pt-4 "
                                        placeholder=""
                                        type="text"
                                        identity="IndividualContribution"
                                        name="name"
                                        // value={props.contributionDetails.name}
                                        value={
                                          props.individualProfile.firstName +
                                          " " +
                                          props.individualProfile.lastName
                                        }
                                        handleChange={props.handleChange}
                                        disabled={true}
                                      />
                                    </div>
                                  </div>
                                </td>

                                <td className="first:pl-8 pr-5 text-lg pb-9 w-[20%] text-left overflow-unser-part">
                                  <div className="text-[#757575] text-xl min-w-[100%]">
                                    <DropdownSelect
                                      drpIdentity={"Role"}
                                      optionArray={props.roles}
                                      setFilterParameters={
                                        props.setFilterParameters
                                      }
                                      value={
                                        props.individualContribution.roleId
                                      }
                                    />
                                    <Validation
                                      error={props.validState.error.roleId}
                                    />
                                  </div>
                                </td>
                                <td className="first:pl-8 pr-5 text-lg pb-9 w-[18%] text-center">
                                  <div className="text-[#757575] text-xl min-w-[100%]">
                                    <DatePicker
                                      name="startDate"
                                      identity="IndividualContribution"
                                      // value={props.contributionDetails.startDate}
                                      value={moment(props.individualContribution.startDate).format("yyyy-MM-DD")}
                                      handleChange={props.handleChange}
                                    />
                                  </div>
                                </td>
                                <td className="first:pl-8 pr-5 text-lg pb-9 w-[18%] text-center">
                                  <div className="text-[#757575] text-xl min-w-[100%]">
                                    <DatePicker
                                      identity="IndividualContribution"
                                      name="endDate"
                                      // value={props.contributionDetails.endDate}
                                      value={moment(
                                        props.individualContribution.endDate
                                      ).format("yyyy-MM-DD")}
                                      handleChange={props.handleChange}
                                    />
                                  </div>
                                </td>
                                <td className="first:pl-8 last:pr-5 text-lg w-[10%] text-center  pb-9 px-2 tble-td-txt">
                                  <div className="action-part text-xl">
                                    <a
                                      className="text-[#c00000] underline"
                                      onClick={() => {
                                        { props.isValidIndividualContribution ? setEditDetails(0) : setEditDetails(contribution.individualContributionId) }
                                        ; props.saveContribution(contribution.individualContributionId);
                                      }}
                                    >
                                      Save
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <tr className="custom-tbl-td-contribution border-b border-[#c9c9c9]">
                                <td className="first:pl-8 last:pr-5 w-[34%] text-left tbl-th text-[#757575]">
                                  <div className="text-[#757575] text-xl min-w-[100%]">
                                    {contribution.name}
                                  </div>
                                </td>
                                <td className="first:pl-8 last:pr-5 w-[20%] text-left tbl-th text-[#757575]">
                                  <div className="text-[#757575] text-xl min-w-[100%]">
                                    {contribution.roleName}
                                  </div>
                                </td>
                                <td className="first:pl-8 last:pr-5 w-[18%] text-left tbl-th text-[#757575]">
                                  <div className="text-[#757575] text-xl min-w-[100%]">
                                    {contribution.displayStartDate}

                                    {/* {moment(contribution.startDate).format(
                                          "DD MMM YYYY"
                                        )} */}
                                  </div>
                                </td>
                                <td className="first:pl-8 last:pr-5 w-[18%] text-left tbl-th text-[#757575]">
                                  <div className="text-[#757575] text-xl min-w-[100%]">
                                    {/* {moment(contribution.endDate).format(
                                          "DD MMM YYYY"
                                        )} */}
                                    {contribution.displayEndDate}
                                  </div>
                                </td>
                                <td className="first:pl-8 last:pr-5 w-[10%] text-center text-lg pb-9 px-2 tble-td-txt">
                                  <div className="action-part text-xl space-x-8">
                                    <a
                                      className="text-[#7B97AA] underline"
                                      // onClick={() => {
                                      //   setEditDetails(contribution.individualContributionId),{()=> props.editContribution(contribution.individualContributionId,contribution)}
                                      // }
                                      // }
                                      onClick={() =>
                                        editContribution(
                                          contribution.individualContributionId,
                                          contribution
                                        )
                                      }
                                    >
                                      Edit
                                    </a>
                                    <a
                                      className="text-[#7B97AA] underline"
                                      // onClick={() => {
                                      //   setEditDetails(contribution.individualContributionId),{()=> props.editContribution(contribution.individualContributionId,contribution)}
                                      // }
                                      // }
                                      onClick={() => props.deleteIndividualContribution(contribution.individualContributionId)}
                                    >
                                      Delete
                                    </a>
                                    {/* <ActionDeleteButton /> */}
                                  </div>
                                </td>
                                {/* <td>
                                    <ActionDeleteButton />
                                  </td> */}
                              </tr>
                            )}
                            {/* vfkjbhd */}
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default IndividualContribution;