import moment from "moment";
import React from "react";
import TextInput from "../../../components/UIComponent/TextInput";
import Validation from "../../../utils/validation/ValidationText";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";
import DatePicker from "../../datePicker/DatePicker";
import DropdownSelect from "../../dropdown/Dropdown";
import MultiSelectDropdown from "../../dropdown/MultiSelectDropdown";
import Option from "../../dropdown/multiSelectDropdownOption/MultiSelectOption";

function OrganisationBasicInformation(props) {
  return (
    <div>
      <div className="w-full">
        <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
          <div className="grid grid-cols-12 gap-6 w-full ">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Target Market
                  </h2>

                  <MultiSelectDropdown
                    drpIdentity="TargetMarket"
                    options={props.targetMarket}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={true}
                    value={props.organizationBusinessDetails.selectedTargetMarketArr}
                  />
                  <Validation
                    error={props.validState.error.selectedTargetMarketArr}
                  />
                </div>
              </div>
            </div>

            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Authorized Cap
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"AuthorizedCap"}
                        optionArray={props.authorizedCaps}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.authorizedCapId}
                      />
                    </div>
                    <Validation
                      error={props.validState.error.authorizedCapId}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Share Holding Local
                    {/* <span className="text-[#c00000]">*</span> */}
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"ShareHoldingLocal"}
                        optionArray={props.shareHoldingLocals}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.shareHoldingLocalId}
                      // onBlur={(e) =>
                      //   props.validate(
                      //     "corporateMemberRecordTypeId",
                      //     props.corporateSIISAccount
                      //   )
                      // }
                      />
                    </div>
                    <Validation
                      error={props.validState.error.shareHoldingLocalId}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Rev From Outside SG
                    {/* <span className="text-[#c00000]">*</span> */}
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"RevFromOutsideSG"}
                        optionArray={props.revFromOutsideSG}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.revFromOutsideSGId}
                      // onBlur={(e) =>
                      //   props.validate(
                      //     "corporateMemberRecordTypeId",
                      //     props.corporateSIISAccount
                      //   )
                      // }
                      />
                    </div>
                    <Validation
                      error={props.validState.error.revFromOutsideSGId}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Paid-Up Capital
                    {/* <span className="text-[#c00000]">*</span> */}
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"PaidUpCap"}
                        optionArray={props.paidUpCaps}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.paidUpCapId}
                      // onBlur={(e) =>
                      //   props.validate(
                      //     "corporateMemberRecordTypeId",
                      //     props.corporateSIISAccount
                      //   )
                      // }
                      />
                    </div>
                    <Validation
                      error={props.validState.error.paidUpCapId}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Share Holding Foreign
                    {/* <span className="text-[#c00000]">*</span> */}
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"ShareHoldingForeign"}
                        optionArray={props.shareHoldingForeign}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.shareHoldingForeignId}
                      // onBlur={(e) =>
                      //   props.validate(
                      //     "corporateMemberRecordTypeId",
                      //     props.corporateSIISAccount
                      //   )
                      // }
                      />
                    </div>
                    <Validation
                      error={props.validState.error.shareHoldingForeignId}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Staff Strength
                    {/* <span className="text-[#c00000]">*</span> */}
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"StaffStrength"}
                        optionArray={props.companyEmploymentSize}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.staffStrengthId}
                      // onBlur={(e) =>
                      //   props.validate(
                      //     "corporateMemberRecordTypeId",
                      //     props.corporateSIISAccount
                      //   )
                      // }
                      />
                    </div>
                    <Validation
                      error={props.validState.error.areaofExpertise}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Updated By
                    {/* <span className="text-[#c00000]">*</span> */}
                  </h2>
                  <div className="disabled-input flex items-center">
                    <DropdownSelect
                      disabled={true}
                      drpIdentity={"updatedById"}
                      optionArray={props._by}
                      setFilterParameters={props.setFilterParameters}
                      value={props.organizationBusinessDetails.updatedBy}
                    // onBlur={(e) => props.validate("updatedById", props.corporateSIISAccount)}
                    />
                    <Validation
                      error={props.validState.error.areaofExpertise}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Last Updated Date
                    {/* <span className="text-[#c00000]">*</span> */}
                  </h2>
                  <div className="disabled-input">
                    {props.organizationBusinessDetails.updatedAt === "" || props.organizationBusinessDetails.updatedAt === null ?
                      <TextInput
                        disabled={true}
                        placeholder=""
                        type="text"
                        name="linkedIn"
                        value="None"
                        identity="BasicInformation"
                      />
                      :
                      <DatePicker
                        isDisable={true}
                        name="updatedAt"
                        handleChange={props.handleChange}
                        identity="updatedAt"
                        value={moment(props.organizationBusinessDetails.updatedAt).format("yyyy-MM-DD")}
                      />
                    }
                  </div>
                  {/* <div className="disabled-input flex items-center">
                    
                    <DatePicker
                      name="updatedAt"
                      isDisable={true}
                      // handleChange={props.handleChange}
                      identity="updatedAt"
                      value={moment(props.organizationBusinessDetails.updatedAt).format("yyyy-MM-DD")}
                    />

                    <Validation
                      error={props.validState.error.areaofExpertise}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Save Button */}
        <div className="grid grid-cols-12 gap-6 pb-10">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
            <a
              className="flex items-center justify-end"
            //href="/CreateCorporateMembers"
            >
              {
                props && props.isLoading === true ?
                  (
                    <ButtonLoader />
                  )
                  :
                  (
                    <button
                      className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                      onClick={() => props.onSave()}
                    >
                      Save
                    </button>
                  )
              }
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OrganisationBasicInformation;