import moment from 'moment';
import React from 'react'
import ModalBasic from '../../pages/component/ModalBasic'
import ValidationText from '../../utils/validation/ValidationText';
import DatePicker from '../datePicker/DatePicker';
import DropdownSelect from '../dropdown/Dropdown';
import TextInput from '../UIComponent/TextInput';

export default function CancelAllBookingsModel(props) {
    return (
        <>
            <ModalBasic
                id="cancel-booking-modal"
                className="cancel-booking-modal"
                modalOpen={props.setCancelAllBookingsModel}
                setModalOpen={props.setOpenModal}
                title="Cancel All Bookings"
            >
                <div className="bg-white mb-10">
                    <div className="">
                        <div className="grid grid-cols-12 gap-6 w-full ">
                            <div className="col-span-12 2xl:pt-7 lg:mt-4 items-center">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="col-span-12">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                                                <div className="flex items-center">
                                                    <h3 className="small-title font-bold theme-color px-12">
                                                        Effective Date
                                                    </h3>
                                                </div>
                                                <div className="flex items-center px-12">
                                                    <DatePicker
                                                        name="creditNoteMonth"
                                                        handleChange={props.handleChangeForCreditNote}
                                                        identity="CreditNote"
                                                        value={props.cancelBookingDetails.creditNoteMonth === "" || props.cancelBookingDetails.creditNoteMonth === null ? moment().format("yyyy-MM-DD") : moment(props.cancelBookingDetails.creditNoteMonth).format("yyyy-MM-DD")}
                                                        minDate={moment().format("yyyy-MM-DD")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 w-full ">
                        <div className="col-span-12 2xl:pt-7 lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                                            <div className="flex items-center">
                                                <h3 className="small-title font-bold theme-color px-12">
                                                    Cancellation Reason
                                                </h3>
                                            </div>
                                            <div className="flex items-center w-full px-12" >
                                                <DropdownSelect
                                                    disabled={false}
                                                    drpIdentity={"cancel_reason"}
                                                    optionArray={props.cancellationReason}
                                                    value={props.cancelBookingDetails.cancellationReasonId}
                                                    setFilterParameters={props.setFilterParameters}
                                                    onBlur={(e) => props.validate("cancellationReasonId", props.cancelBookingDetails)}
                                                />
                                            </div>
                                            <div className="flex items-center w-full px-12">
                                                <ValidationText
                                                    error={props.validCreditNote.error.cancellationReasonId}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {props.isOtherCancellationReason ?
                        <>
                            <div className="grid grid-cols-12 gap-6 w-full ">
                                <div className="col-span-12 2xl:pt-7 lg:mt-4 items-center">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="col-span-12">
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                                    <div className="flex items-center">
                                                        <h3 className="small-title font-bold theme-color px-12">
                                                            Others
                                                        </h3>
                                                    </div>
                                                    <div className="flex items-center px-12">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="cancellationReason"
                                                            value={props.cancelBookingDetails.cancellationReason}
                                                            identity="CreditNote"
                                                            handleChange={props.handleChangeForCreditNote}
                                                        //onBlur={(e) => props.validate2("cancellationReason", props.addEditCreditNote)}
                                                        />
                                                    </div>
                                                    <div className="flex items-center px-12">
                                                        <ValidationText
                                                            error={props.validCreditNote.error.cancellationReason}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : null
                    }

                    {/* Delete and Save Button */}
                    <div className="grid grid-cols-12 gap-6 w-full pt-16 px-10 ">
                        <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                            <div className="flex items-center justify-center">
                                <div className="savebtn">
                                    <button className=" px-14 py-3 bg-[#C00000] text-xl text-white font-semibold"
                                        onClick={() => props.addEdit()}
                                    >
                                        Generate
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBasic>
        </>
    )
}
